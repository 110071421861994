import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  FaBuildingColumns,
  FaBuildingShield,
  FaPeopleLine,
} from 'react-icons/fa6';
import { IoIosSend } from 'react-icons/io';
import { PiWarningCircleFill } from 'react-icons/pi';
import { RiComputerLine } from 'react-icons/ri';
import ActiveLink from 'common/components/dashboard/active-link';
import { useNavigate } from 'react-router-dom';
import { logout, selectUser } from 'redux/features/auth/authSlice';
import DashboardLayout from 'common/layouts/dashboard';
import ConfirmActionModal from 'common/components/confirm-action-modal';

const DashboardWrapperLayout = ({ children, title, buttons }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [showLogoutModal, setShowLogoutModal] = React.useState(false);

  const links = (
    <>
      <ActiveLink Icon={RiComputerLine} to={'/dashboard/home'} text='Home' />
      <ActiveLink Icon={IoIosSend} to={'/dashboard/plans'} text='Plans' />
      <ActiveLink
        Icon={PiWarningCircleFill}
        to={'/dashboard/claims'}
        text='Claims'
      />
      <ActiveLink
        Icon={FaPeopleLine}
        to={'/dashboard/members'}
        text='Members'
      />
      <ActiveLink
        Icon={FaBuildingShield}
        to={'/dashboard/properties'}
        text='Properties'
      />
      <ActiveLink
        Icon={FaBuildingColumns}
        to={'/dashboard/owners'}
        text='Owners'
      />
    </>
  );

  return (
    <div>
      <DashboardLayout
        title={title}
        user={user}
        links={links}
        onLogoutClick={() => setShowLogoutModal(true)}
        buttons={buttons}
        children={children}
      />

      {showLogoutModal ? (
        <ConfirmActionModal
          action='logout'
          description={`Reminder: Always log out when using public or shared computers to keep your account secure.`}
          onConfirmClick={() => {
            dispatch(logout());
            navigate('/login');
          }}
          onCancel={() => setShowLogoutModal(false)}
        />
      ) : null}
    </div>
  );
};

export default DashboardWrapperLayout;
