import React from 'react';
import { claimStatusEnum } from 'common/utils/enums';
import StatusLabelView from 'common/components/status-label';

const StatusLabel = ({ status = 'In Review' }) => {
  // DATA INITIALIZATION
  let colorScheme = '';

  switch (status) {
    case claimStatusEnum.SUBMITTED:
      colorScheme = 'yellow';
      break;
    case claimStatusEnum.IN_REVIEW:
      colorScheme = 'yellow';
      break;
    case claimStatusEnum.DRAFT:
      colorScheme = 'gray';
      break;
    case claimStatusEnum.DENIED:
      colorScheme = 'red';
      break;
    case claimStatusEnum.COMPLETED:
      colorScheme = 'green';
      break;
    case claimStatusEnum.APPROVED:
      colorScheme = 'blue';
      break;
    case claimStatusEnum.CONFIRMED:
      colorScheme = 'blue';
      break;
    case claimStatusEnum.EXPIRED:
      colorScheme = 'red';
      break;
    default:
      colorScheme = 'gray';
  }
  return <StatusLabelView colorScheme={colorScheme} status={status} />;
};

export default StatusLabel;
