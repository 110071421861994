import { useState, useCallback } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FiLock } from 'react-icons/fi';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';

const PasswordInput = ({
  placeholderText = '',
  name = '',
  onChange,
  value,
  onFocus,
  onBlur,
  disabled,
}) => {
  // STATES
  const [isHidden, setIsHidden] = useState(true);
  const { trackFieldPopulate } = useUserTracker();
  // These values are filtered, only says that the field was changed
  const _onBlur = useCallback((event) => {
    if (onBlur) {
      onBlur(event);
    }
    trackFieldPopulate(name);
  }, []);
  return (
    <div className={`flex items-center justify-between w-full border-solid border border-gray-200 rounded-md bg-white h-9 px-3 gap-x-3 ${disabled && 'bg-gray-100 opacity-70'}`}>
      {/* icon */}
      <FiLock className='text-lg text-gray-500' />

      {/* input */}
      <input
        placeholder={placeholderText}
        name={name}
        value={value}
        type={isHidden ? 'password' : 'text'}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={_onBlur}
        disabled={disabled}
        className='h-full w-full border-none [outline:none] text-base placeholder:text-gray-500 placeholder:text-base disabled:bg-inherit disabled:opacity-70'
      />

      {/* password ishidden indicator */}
      {isHidden ? (
        <AiOutlineEyeInvisible
          onClick={() => setIsHidden(!isHidden)}
          className='text-lg text-gray-500 cursor-pointer'
        />
      ) : (
        <AiOutlineEye
          onClick={() => setIsHidden(!isHidden)}
          className='text-lg text-gray-500 cursor-pointer'
        />
      )}
    </div>
  );
};

export default PasswordInput;
