import { HiBadgeCheck } from 'react-icons/hi';
import { Flex, Icon } from '@chakra-ui/react';

const ProgressBar = ({ children }) => {
  return <Flex className='w-full gap-1.5 rounded-md bg-white overflow-hidden flex-col sm:flex-row'>{children}</Flex>;
};

const ProgressBarStep = ({ title, complete = false, inProgress = false }) => {
  return (
    <Flex
      className={`w-full gap-3 py-2 px-3 items-center font-medium tracking-wide text-center text-sm
        justify-between sm:justify-center
        rounded-md sm:rounded-none sm:first:rounded-l-md sm:last:rounded-r-md
        ${complete ? 'bg-green-100' : inProgress ? 'bg-green-500' : 'bg-slate-100'} 
        ${complete || inProgress ? 'text-green-800' : 'text-gray-500'}`}
    >
      {complete ? (
        <Icon as={HiBadgeCheck} boxSize={4} className='order-last sm:order-first' />
      ) : (
        <div className='w-4 h-4 sm:hidden order-last sm:order-first' />
      )}
      {title}      
    </Flex>
  );
};

export { ProgressBar, ProgressBarStep };
