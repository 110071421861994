import React from 'react';

const getDocumentName = ({ documentUrl }) => {
  // Get the document name from the url
  if (!documentUrl) return '';
  const regex = /https:\/\/cios-api-images\.s3\.amazonaws\.com\/listings\/(.+)/;
  const match = documentUrl.match(regex);
  const documentName = match ? match[1] : '';

  return documentName;
};

const DocumentPreview = ({ label, url }) => {
  const _label = label || 'uploaded-document';
  return (
    <div className='rounded-md border border-[#CED0CE] px-3 h-10 flex w-full justify-start gap-3 items-center '>
      <button
        onClick={(e) => {
          e.stopPropagation();
          window.open(url, '_blank');
        }}
        className='rounded border border-[#CED0CE] py-1 px-2 text-[#191923] text-sm font-medium cursor-pointer hover:bg-gray-200 text-nowrap'
      >
        View
      </button>
      <span className='text-[#6A6C70] text-bases font-normal truncate ...'>{_label}</span>
    </div>
  );
};

export default DocumentPreview;
