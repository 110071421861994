import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const Breadcrumbs = () => {
  const location = useLocation();
  const paths = location.pathname.split('/').filter(Boolean);

  return (
    <nav aria-label="breadcrumb">
      <ol className="flex items-center text-gray-500 capitalize flex-wrap">
        {paths.map((segment, index) => {
          const to = '/' + paths.slice(0, index + 1).join('/');
          const isLast = index === paths.length - 1;

          return (
            <li
              key={index}
              className={`flex items-center text-sm`}
            >
              {index > 0 && (
                <span className="mx-1 text-gray-500">/</span>
              )}
              {!isLast ? (
                <Link
                  to={to}
                  className="text-gray-500 hover:text-black transition-colors"
                >
                  {decodeURIComponent(segment)}
                </Link>
              ) : (
                <span className="text-black">
                  {decodeURIComponent(segment)}
                </span>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;