import LogoImage from "common/components/logo-image";
import NavBar from "common/components/dashboard/navbar";
import ProfileLink from "common/components/dashboard/profile-link";
import Breadcrumbs from "common/components/breadcrumbs";

const DashboardLayout = ({
  children,
  admin = false,
  links,
  user,
  onLogoutClick,
  title,
  buttons,
}) => {
  return (
    <main className="h-screen flex mobile:flex-col">
      {/* aside */}
      <aside className="h-full w-full max-w-[240px] flex flex-col relative gap-6 px-4 py-8 border-r border-gray-200 mobile:hidden">
        {/* icon */}
        <div className={`flex ${!admin && "pt-1 pb-2"}`}>
          <LogoImage className="ml-4 mobile:ml-3" size="s" />
          {admin && (
            <span className="ml-6 mobile:ml-3 text-sm uppercase font-semibold tracking-wider">
              Admin Portal
            </span>
          )}
        </div>

        {/* link buttons */}
        <div className="flex flex-col gap-3">{links}</div>

        {/* profile details and logout */}
        <ProfileLink user={user} onClick={onLogoutClick} />
      </aside>

      {/* mobile: hamburger menu */}
      <NavBar links={links} user={user} onLogoutClick={onLogoutClick} />

      {/* main section */}
      <section className="w-full relative flex flex-col overflow-scroll relative">
        {title ? (
          <div className="px-4 py-3 sm:px-6 sm:py-4 lg:p-8 flex w-full gap-4 sticky top-0 bg-white border-b border-gray-200 justify-between z-20 flex-col sm:flex-row items-start sm:items-center">
            <div className="flex flex-col gap-2">
              {/* breadcrumb */}
              <Breadcrumbs />

              {/* title */}
              <h1 className="text-3xl font-display tracking-tight">{title}</h1>
            </div>
            {buttons}
          </div>
        ) : null}

        {/* Content */}
        <div className="p-4 sm:p-6 lg:p-8 flex flex-col w-full gap-8 h-full">
          {children}

          {/* Transparent div to act as spacer on mobile */}
          <div className='bg-transparent text-transparent'>
            ReLease Coverage Company Inc. © 2025
          </div>
        </div>
      </section>
    </main>
  );
};

export default DashboardLayout;
