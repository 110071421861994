import { useState } from 'react';
import { toast } from 'react-toastify';
import { BiCopy } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteTenantMutation,
  useClaimAccountTenantReqMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  ViewIcon,
  AddIcon,
  RepeatIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import SortButton from 'common/components/sort-button';
import { Table, HeaderRow, HeaderCell, TableBody, BodyRow, TableCell } from 'common/components/data-table';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { formatPhoneNumber } from 'react-phone-number-input';
import { StatusLabel as DocumentStatusLabel } from 'components/documents/status-label';
import { StatusLabel as VerificationStatusLabel } from 'components/verification/status-label';
import ConfirmActionModal from 'common/components/confirm-action-modal';
import copyToClipboard from 'common/utils/copyToClipboard';

// Only show the Claim Account button if the email is not confirmed
function ClaimAccountButton({ userId, emailStatus }) {
  const [claimAccount, { isLoading }] = useClaimAccountTenantReqMutation();
  const [claimUrl, setClaimUrl] = useState('');

  return (
    <div className={`relative ${emailStatus && 'hidden'}`}>
      <MenuItem
        icon={<RepeatIcon />}
        disabled={isLoading}
        onClick={() => {
          claimAccount({ id: userId })
            .unwrap()
            .then(({ url }) => {
              setClaimUrl(url);
              toast.success('Activation link sent to member');
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Send failed');
            });
        }}
      >
        Send Activation link
      </MenuItem>
      {claimUrl &&
        <MenuItem
          icon={<BiCopy />}
          onClick={() => {
            copyToClipboard(claimUrl);
            toast.success(`Copied Activation Link to clipboard`);
          }}
        >
          Copy Activation Link
        </MenuItem>
      }
    </div>
  );
}

const MembersTable = ({ members, setSort, sortVal, isRounded=true }) => {
  // DATA INITIALIZATION
  const navigate = useNavigate();
  const [
    deleteTenant,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useDeleteTenantMutation();
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });

  const gridLayout = 'grid-cols-5 mobile:grid-cols-2';

  return (
    <Table>
      {deleteModal.open && (
        <ConfirmActionModal
          action='deactivate this member'
          onConfirmClick={() => {
            deleteTenant({ id: deleteModal.id })
              .unwrap()
              .then(() => {
                setDeleteModal({ open: false, id: null });
                navigate('/dashboard/members');
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Delete Failed');
              });
          }}
          onCancel={() => {
            setDeleteModal({ open: false, id: null });
          }}
        />
      )}

      {/* head */}
      <HeaderRow gridLayout={gridLayout} isRounded={isRounded}>
        <HeaderCell>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='firstName'
            label='Name'
          />
        </HeaderCell>
        <HeaderCell sortColumn className='mobile:hidden'>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='email'
            label='Contact'
          />
        </HeaderCell>
        <HeaderCell sortColumn className='mobile:hidden'>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='createdAt'
            label='Signed Up'
          />
        </HeaderCell>
        <HeaderCell className='mobile:hidden'>
          Identity Status
        </HeaderCell>
        <HeaderCell sortColumn>          
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='emailConfirmed'
            label='Account Status'
          />
        </HeaderCell>
      </HeaderRow>

      {/* body */}
      <TableBody>
        {members?.map((member, index) => (
          <BodyRow
            gridLayout={gridLayout}
            key={index}
            onClick={() => {
              navigate(`/dashboard/members/${member.id}`);
            }}
          >
            {/* Name and ID */}
            <TableCell
              top={member?.name || `${member?.firstName} ${member?.lastName}`}
              bottom={member?.id}
            />

            {/* Email and Phone Number */}
            <TableCell
              top={member?.email}
              bottom={formatPhoneNumber(member?.phone)}
              topLink={`mailto:${member?.email}`}
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
              className='mobile:hidden'
            />

            {/* Created and Signup Code */}
            <TableCell
              top={formatDateToHumanDate(member?.createdAt)}
              bottom={member?.signupCode ? member?.signupCode : '-'}
              className='mobile:hidden'
            />

            {/* ID Verification */}
            <TableCell className='mobile:hidden'>
              <DocumentStatusLabel status={member?.idVerification} size='sm' />
            </TableCell>

            <TableCell
              className='justify-between'
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            >
              <VerificationStatusLabel status={member?.emailConfirmed} size='sm' />
              <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={<HamburgerIcon />}
                    variant='ghost'
                  />
                  <MenuList>
                    <MenuItem 
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/dashboard/members/${member?.id}`);
                      }}
                      icon={<ViewIcon />}
                    >
                      View Member
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/dashboard/members/${member?.id}/edit`);
                      }}
                      icon={<ViewIcon />}
                    >
                      Edit Member
                    </MenuItem>
                    <MenuItem 
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/dashboard/plans/new?userId=${member?.id}`);
                      }}
                      icon={<AddIcon />}
                    >
                      Add Plan to Member
                    </MenuItem>

                    <ClaimAccountButton
                      userId={member?.id}
                      emailStatus={member?.emailConfirmed}
                    />

                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteModal({ open: true, id: member.id });
                      }}
                      icon={<CloseIcon />}
                    >
                      Deactivate member
                    </MenuItem>
                  </MenuList>
                </Menu>
            </TableCell>
          </BodyRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default MembersTable;
