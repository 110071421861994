import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

const LoadingSpinner = ({
  spinnerColor = '#6B7280',
}) => {
  const containerClass = `w-full flex items-center justify-center`;
  return (
    <div className={containerClass}>
      <RotatingLines
        strokeColor={spinnerColor}
        strokeWidth='4'
        animationDuration='0.75'
        width='40'
        visible={true}        
      />
    </div>
  );
};

export default LoadingSpinner;
