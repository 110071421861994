import { Icon, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import CheckBox from 'common/components/checkbox';
import { FaFilter } from 'react-icons/fa6';

function Filter({ statusOptions, statuses, setCurrentStatuses }) {
  const handleToggle = (status) => {
    if (statuses.includes(status)) {
      setCurrentStatuses(statuses.filter((s) => s !== status));
    } else {
      setCurrentStatuses([...statuses, status]);
    }
  };

  return (
  <Accordion
      allowMultiple
      className='flex w-full flex-col border-none'
    >
      <AccordionItem className='border border-gray-200 rounded-md overflow-hidden'>
        <h2>
          <AccordionButton className='bg-gray-50 hover:bg-slate-100 px-3'>
            <div className='inline-flex items-center gap-3 w-full text-left text-md font-medium tracking-wide capitalize'>
              <Icon
                as={FaFilter}
                boxSize={3}
                className='text-gray-500'
              />
              Filter by Status
            </div>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel className='border-t py-4'>
          {/* Options */}
          <div className='grid grid-cols-4 mobile:grid-cols-2 gap-4'>
            {Object.keys(statusOptions).map((key) => (
              <CheckBox
                name={statusOptions[key]}
                onChange={() => handleToggle(statusOptions[key])}
                checked={statuses.includes(statusOptions[key])}
              >
                {statusOptions[key]}
              </CheckBox>
            ))}
          </div>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default Filter;