import { useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import PlansTable from 'components/plans/plans-table';
import { StatusLabel } from 'components/members/status-label';
import { StatusLabel as DocumentStatusLabel } from 'components/documents/status-label';
import { StatusLabel as VerificationStatusLabel } from 'components/verification/status-label';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { isEmpty } from 'utils/isEmpty';
import {
  useGetTenantQuery,
  useDeleteTenantMutation,
  useImpersonateTenantMutation,
  useClaimAccountTenantReqMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import Button from 'common/components/button';
import Toggle from 'common/components/toggle';
import { InfoCard, InfoGrid, InfoField } from 'common/components/info-card';
import { formatPhoneNumber } from 'react-phone-number-input';
import ConfirmActionModal from 'common/components/confirm-action-modal';
import FloatingButtons from 'common/components/floating-action-button';
import EmptyPage from 'common/components/empty-page';
import CopyLink from 'common/components/copy-link';

function ClaimAccountArea({ userId }) {
  const [claimAccount, { isLoading }] = useClaimAccountTenantReqMutation();
  const [claimUrl, setClaimUrl] = useState('');
  return (
    <>
      <Button
        title='Send Activation Email'
        disabled={isLoading}
        onClick={() => {
          claimAccount({ id: userId })
            .unwrap()
            .then(({ url }) => {
              setClaimUrl(url);
              toast.success('Activation link sent to member');
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Send failed');
            });
        }}
        wFull={false}
        className='px-6'
      />
      {claimUrl && (
        <Button
        title='Copy Activation Link'
        wFull={false}
        onClick={() => {
          navigator.clipboard.writeText(claimUrl);
          toast.success('Activation link copied to clipboard');
        }}
        theme='secondary'
      />
      )}
    </>
  );
}

const MemberView = () => {
  const navigate = useNavigate();
  const { memberId } = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const {
    data: tenantData,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
    isFetching: isGetFetching,
  } = useGetTenantQuery(memberId);

  const [
    deleteTenant,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useDeleteTenantMutation();

  const [impersonateTenant, { isLoading: isImpersonateLoading }] =
    useImpersonateTenantMutation();

  return (
    <DashboardWrapperLayout
      title={tenantData?.name || `${tenantData?.firstName} ${tenantData?.lastName}`}
      buttons={
        tenantData?.id && (
          <Toggle
            selected={'view'}
            editPath={`/dashboard/members/${tenantData?.id}/edit`}
            viewPath={`/dashboard/members/${tenantData?.id}`}
          />
        )
      }
    >     
      {isGetError ? (
        <EmptyPage
          title='Error Loading Member'
          description='Failed to load member details. Please try again.'
          buttonText={'Reload Page'}
          onClick={() => {
            window.location.reload();
          }}
          isError
        />
      ) : isGetLoading || isGetFetching ? (
        <EmptyPage
          title='Loading Member Details'
          description='Please wait while we load member details.'
          isLoading
        />
      ) : !tenantData ? (
        <EmptyPage
          title='Member Not Found'
          description='The member you are looking for does not exist.'
          buttonText={'Go Back'}
          onClick={() => {
            navigate('/dashboard/members');
          }}
        />
      ) : (
      <>
        {/* Delete Modal */}
        {deleteModalOpen && (
          <ConfirmActionModal
            action='deactivate this member'
            onConfirmClick={() => {
              deleteTenant({ id: memberId })
                .unwrap()
                .then(() => {
                  navigate('/dashboard/members');
                })
                .catch((e) => {
                  toast.error(e?.data?.msg || 'Delete Failed');
                });
            }}
            onCancel={() => {
              setDeleteModalOpen(false);
            }}
          />
        )}

        {/* Action buttons */}
        <FloatingButtons>
          <Button
            onClick={() => {
              navigate(`/dashboard/plans/new?userId=${tenantData.id}`);
            }}
            title='Add Plan'
            wFull={false}
          />
          {/* Show account activation button conditionally based on the email confirmation status */}
          {!tenantData?.emailConfirmed && (
            <ClaimAccountArea userId={tenantData?.id} />
          )}          
          <Button
            disabled={isImpersonateLoading}
            onClick={() => {
              impersonateTenant({ id: tenantData.id })
                .unwrap()
                .then(({ token }) => {
                  let url = `${process.env.REACT_APP_IMPERSONATION_URL}?token=${token}`;
                  window.open(url, '_blank');
                });
            }}
            theme='secondary'
            title='Log In As User'
            wFull={false}
          />
          <Button
            onClick={() => {
              setDeleteModalOpen(true);
            }}
            theme='secondary'
            title='Deactivate User'
            wFull={false}
          />
        </FloatingButtons>

        {/* Member Details Table */}
        <InfoCard title='Member Information'>
          <InfoGrid>
            <InfoField title='Name'>
              {tenantData?.name || `${tenantData?.firstName} ${tenantData?.lastName}`}
            </InfoField>
            {tenantData?.phone ? (
              <InfoField title='Phone'>
                {formatPhoneNumber(tenantData?.phone)}
              </InfoField>
            ) : null}
            <InfoField title='Email' className='col-span-2'>
              {tenantData?.email}
            </InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Member Status'>
              <StatusLabel status={tenantData.status} size='base' />
            </InfoField>
            <InfoField title='ID Verification'>
              <DocumentStatusLabel
                status={tenantData?.idVerification}
                size='base'
              />
            </InfoField>
            <InfoField title='Email Verification'>
              <VerificationStatusLabel
                status={tenantData?.emailConfirmed}
                size='base'
              />
            </InfoField>
            <InfoField title='Phone Verification'>
              <VerificationStatusLabel
                status={tenantData?.phoneConfirmed}
                size='base'
              />
            </InfoField>
          </InfoGrid>
        </InfoCard>

        {/* Member Plan table */}
        {isEmpty(tenantData?.plans) ? (
          <EmptyPage
            title='No Plans Under Member'
            description='This member does not have any plans.'          
          />
        ) : (
          <div className='flex flex-col gap-4'>
            <h2 className='text-lg font-medium'>Member Plans</h2>
            <PlansTable
              showMember={false}
              plans={tenantData?.plans}
            />
          </div>
        )}
      </>
      )}
    </DashboardWrapperLayout>
  );
};

export default MemberView;
