import { useNavigate } from 'react-router-dom';
import StatusLabel from 'components/claims/status-label';
import SortButton from 'common/components/sort-button';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton
} from '@chakra-ui/react';
import {
  ViewIcon, HamburgerIcon,
  CopyIcon,
  EditIcon
} from '@chakra-ui/icons';
import {
  Table,
  HeaderRow,
  HeaderCell,
  TableBody,
  BodyRow,
  TableCell,
} from 'common/components/data-table';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import copyToClipboard from 'common/utils/copyToClipboard';
import { toast } from 'react-toastify';

const ClaimsTable = ({ claims, isRounded = true, sortVal, setSort }) => {
  // DATA INITIALIZATION
  const navigate = useNavigate();  

  const commonGridLayout = 'grid-cols-5 mobile:grid-cols-2'

  return (
    <Table>
      {/* head */}
      <HeaderRow
        isRounded={isRounded}
        gridLayout={commonGridLayout}
      >
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='statusChanges.updatedAt'
            label='Claim Number'
          />
        </HeaderCell>
        <HeaderCell sortColumn className='mobile:hidden'>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='planId'
            label='Plan'
          />
        </HeaderCell>
        <HeaderCell className='mobile:hidden'>
          Member
        </HeaderCell>
        <HeaderCell className='mobile:hidden'>
          Submission
        </HeaderCell>
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='status'
            label='Status'
          />
        </HeaderCell>
      </HeaderRow>

      {/* body */}
      <TableBody>
        {claims.map((claim, index) => (
          <BodyRow
            key={index}
            onClick={() => {
              navigate(`/dashboard/claims/${claim.id}`);
            }}
            gridLayout={commonGridLayout}
          >
            {/* Claim Number and Submitted Date */}
            <TableCell
              top={claim?.id}
              bottom={`Last Update: ${formatDateToHumanDate(claim?.statusChanges?.at(-1)?.updatedAt)}`}
            />

            {/* Plan */}
            <TableCell
              top={`${claim?.planId?.lease?.address?.streetAddress1}, ${claim?.planId?.lease?.address?.city}, ${claim?.planId?.lease?.address?.state}`}
              bottom={claim?.planId?.id}
              className='mobile:hidden'
            />

            {/* Member */}
            <TableCell
              top={claim?.userId?.name || `${claim?.userId?.firstName} ${claim?.userId?.lastName}`}
              bottom={claim?.userId?.email}
              className='mobile:hidden'
            />

            {/* Cancellation */}
            <TableCell
              top={`${formatDateToHumanDate(claim?.submission?.moveOutStart)} - ${formatDateToHumanDate(claim?.submission?.moveOutEnd)}`}
              bottom={claim?.claimType === 'cancelAnytime' ? 'Cancel Lease' : 'Early Move Out'}
              className='mobile:hidden'
            />

            {/* Status and Edit button */}
            <TableCell
              className='justify-between'
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            >
              <StatusLabel size='sm' status={claim?.status} />
              
              {/* Menu button */}
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<HamburgerIcon />}
                  variant='ghost'
                />
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/claims/${claim?.id}`);
                    }}
                    icon={<ViewIcon />}
                  >
                    View Claim Details
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/claims/${claim?.id}/edit`);
                    }}
                    icon={<EditIcon />}
                  >
                    Edit Claim Details
                  </MenuItem>                
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(claim?.id);
                      toast.success(`Copied claim number to clipboard`);
                    }}
                    icon={<CopyIcon />}
                  >
                    Copy Claim Number
                  </MenuItem>
                </MenuList>
              </Menu>
            </TableCell>
          </BodyRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ClaimsTable;
