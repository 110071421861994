import {
  Link,
} from '@chakra-ui/react';

const Table = ({ children, className }) => {
  return (
    <table className={`flex flex-col w-full ${className}`}>
      {children}
    </table>
  )
}

const HeaderRow = ({ isRounded, children, gridLayout = 'grid-cols-8', className }) => {
  return (
    <thead className='w-full'>
      <tr className={`w-full grid ${gridLayout} text-left ${isRounded && 'first:rounded-t-md'} bg-gray-50 border border-gray-200 ${className}`}>
        {children}
      </tr>
    </thead>
  )
}

const HeaderCell = ({ children, sortColumn = false, className }) => {
  return (
    <th className={`flex items-center justify-start w-full text-md font-medium tracking-wide ${sortColumn ? 'py-2 px-2' : 'py-2 px-4'} ${className}`}>
      {children}
    </th>
  )
}

const TableBody = ({ children, className }) => {
  return (
    <tbody className={`w-full ${className}`}>
      {children}
    </tbody>
  )
}

const BodyRow = ({ children, key, onClick, gridLayout='grid-cols-8', className }) => {
  return (
    <tr
      key={key}
      onClick={onClick}
      className={`grid cursor-pointer ${gridLayout} w-full border-b border-x border-gray-200 last:rounded-b-md ${className} ${onClick && 'hover:bg-gray-50'}`}
    >
      {children}
    </tr>
  )
}

const TableCell = ({ children, top, bottom, className, onClick, topLink, bottomLink }) => {
  return (
    <td className={`w-full py-2 px-4 flex gap-2 items-center truncate ... text-md ${className}`} onClick={onClick}>
      {children}
      {top && bottom &&
        <div className='flex flex-col'>
          {topLink
          ? <Link href={topLink}>
            <div className='text-md'>
              {top}
            </div>
          </Link>
          : <div className='text-md'>
            {top}
          </div>
          }
          {bottomLink
          ? <Link href={bottomLink}>
            <div className='text-gray-500 m-0 text-sm'>
              {bottom}
            </div>
          </Link>
          : <div className='text-gray-500 m-0 text-sm'>
            {bottom}
          </div>
          }
        </div>
      }
    </td>
  )
}

export {Table, HeaderRow, HeaderCell, TableBody, BodyRow, TableCell}
