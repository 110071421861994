import React from 'react';
import { FaBuildingColumns, FaBuildingShield, FaPeopleLine } from 'react-icons/fa6';
import { IoIosSend } from 'react-icons/io';
import { RiErrorWarningFill } from 'react-icons/ri';
import DashboardCard from 'common/components/dashboard/dashboard-card';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { useGetAdminSummaryQuery } from 'redux/api/ciosAdminApi/ciosAdminApi';

const Home = () => {
  const { data: adminSummary } = useGetAdminSummaryQuery();

  return (
    <DashboardWrapperLayout title='Home'>
      <div className='w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6'>
        {/* cards */}        
        <DashboardCard
          title='Plans'
          path='/dashboard/plans'
          Icon={IoIosSend}
        />
        <DashboardCard
          title='Claims'
          path='/dashboard/claims'
          Icon={RiErrorWarningFill}
        />
        <DashboardCard
          title='Members'
          count={0}
          path='/dashboard/members'
          Icon={FaPeopleLine}
        />
        <DashboardCard
          title='Properties'
          count={0}
          path='/dashboard/properties'
          Icon={FaBuildingShield}
        />
        <DashboardCard
          title='Owners'
          count={0}
          path='/dashboard/owners'
          Icon={FaBuildingColumns}
        />
      </div>
    </DashboardWrapperLayout>
  );
};

export default Home;
