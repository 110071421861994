import { useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import PlansTable from 'components/plans/plans-table';
import Button from 'common/components/button';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { isEmpty } from 'utils/isEmpty';
import {
  useGetPropertyQuery,
  useDeletePropertyMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import Toggle from 'common/components/toggle';
import {
  InfoCard,
  InfoField,
  InfoGrid,
  InfoGridView,
} from 'common/components/info-card';
import RentalType from 'components/properties/rental-type';
import { StatusLabel } from 'components/properties/status-label';
import { formatPhoneNumber } from 'react-phone-number-input';
import CopyLink from 'common/components/copy-link';
import FormatAsCurrency from 'common/utils/formatAsCurrency';
import ConfirmActionModal from 'common/components/confirm-action-modal';
import FloatingButtons from 'common/components/floating-action-button';
import EmptyPage from 'common/components/empty-page';
import { FaBuildingColumns, FaCreditCard, FaFile } from 'react-icons/fa6';

const PropertyView = () => {
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sortVal, setSort] = useState('-createdAt');

  const {
    data: propertyData,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
    isFetching: isGetFetching,
  } = useGetPropertyQuery(propertyId);
  const [
    deleteProperty,
    { isLoading: isDeleteLoading, isFetching: isDeleteFetching },
  ] = useDeletePropertyMutation();

  // Log property data
  // useEffect(() => {
  //   console.log('Property data loaded:', propertyData);
  // }, [propertyData]);

  // Configure all the infofields
  const contactFields = [
    {
      condition: propertyData?.contactName,
      title: 'Contact Name',
      value: propertyData?.contactName,
    },
    {
      condition: propertyData?.email,
      title: 'Contact Email',
      value: propertyData?.email,
    },
    {
      condition: propertyData?.phone,
      title: 'Contact Phone',
      value: formatPhoneNumber(propertyData?.phone),
    },
    {
      condition: propertyData?.website,
      title: 'Website',
      value: propertyData?.website,
    },
  ];

  const rateFields = [
    {
      condition: propertyData?.cancelAnytimeRate,
      title: 'Cancel Anytime Default Rate',
      value: `${(propertyData?.cancelAnytimeRate * 100).toFixed(2)}%`,
    },
    {
      condition: propertyData?.leaveAnytimeRate,
      title: 'Leave Anytime Default Rate',
      value: `${(propertyData?.leaveAnytimeRate * 100).toFixed(2)}%`,
    },
    {
      condition: propertyData?.riskScore,
      title: 'Risk Score',
      value: propertyData?.riskScore,
    },
    {
      condition: propertyData?.kind,
      title: 'Property Type',
      value: <RentalType rentalType={propertyData?.kind} />,
    },
  ];

  const addressFields = [
    {
      condition: true,
      title: 'Street Address 1',
      className: propertyData?.address?.streetAddress2
        ? 'col-span-2'
        : 'col-span-4',
      value: propertyData?.address.streetAddress1,
    },
    {
      condition: propertyData?.address?.streetAddress2,
      title: 'Street Address 2',
      className: 'col-span-2',
      value: propertyData?.address.streetAddress2,
    },
    {
      condition: true,
      title: 'City',
      value: propertyData?.address.city,
    },
    {
      condition: propertyData?.address?.state,
      title: 'State',
      value: propertyData?.address.state,
    },
    {
      condition: true,
      title: 'Postal Code',
      value: propertyData?.address.postalCode,
    },
    {
      condition: true,
      title: 'Country',
      value: propertyData?.address.country,
    },
  ];

  const propertyDefaultsFields = [
    {
      condition:
        propertyData?.propertyDefaults?.terminationPolicy?.noticePeriod,
      title: 'Default Notice Period',
      value: `${propertyData?.propertyDefaults?.terminationPolicy?.noticePeriod} days`,
    },
    {
      condition: propertyData?.propertyDefaults?.terminationPolicy?.penalty,
      title: 'Default Termination Penalty',
      value: FormatAsCurrency(
        propertyData?.propertyDefaults?.terminationPolicy?.penalty,
        propertyData?.paymentInformation?.currency
      ),
    },
    {
      condition:
        propertyData?.propertyDefaults?.terminationPolicy?.buyoutMonths,
      title: 'Default Lease Buyout',
      value: `${propertyData?.propertyDefaults?.terminationPolicy?.buyoutMonths} months`,
    },
    {
      condition: true,
      title: 'Furnished by Default?',
      value: propertyData?.propertyDefaults?.furnished ? 'Yes' : 'No',
    },
  ];

  const ownerContactFields = [
    {
      condition: propertyData?.owner?.email,
      title: 'Email',
      value: propertyData?.owner?.email,
    },
    {
      condition: propertyData?.owner?.phone,
      title: 'Phone',
      value: formatPhoneNumber(propertyData?.owner?.phone),
    },
    {
      condition: propertyData?.owner?.website,
      title: 'Website',
      value: propertyData?.owner?.website,
    },
  ];

  const ownerCommissionFields = [
    {
      condition: true, // Always show revShareRate
      title: 'Rev Share Rate',
      value: `${
        propertyData?.owner?.revShareRate
          ? (propertyData?.owner?.revShareRate * 100).toFixed(2)
          : '0'
      }%`,
    },
    {
      condition: true, // Always show flatCommission
      title: 'Flat Commission',
      value: `${
        FormatAsCurrency(
          propertyData?.owner?.flatCommission,
          propertyData?.paymentInformation?.currency
        ) || '$0.00'
      } Per Plan`,
    },
  ];

  let plansData = propertyData?.plans || [];

  return (
    <DashboardWrapperLayout
      title={propertyData?.name || 'Property Details'}
      buttons={
        <Toggle
          selected={'view'}
          editPath={`/dashboard/properties/${propertyData?.id}/edit`}
          viewPath={`/dashboard/properties/${propertyData?.id}`}
        />
      }
    >
      {isGetError ? (
        <EmptyPage
          title='Error Loading Property'
          subtitle='There was an error loading this property. Please try again.'
          buttonText='Reload Page'
          onClick={() => {
            window.location.reload();
          }}
          isError
        />
      ) : isGetLoading ? (
        <EmptyPage
          title='Loading Property Details'
          subtitle='Please wait while we load property details.'
          isLoading
        />
      ) : !propertyData ? (
        <EmptyPage
          title='No Property Found'
          subtitle='This property does not exist.'
          buttonText={'Go Back'}
          onClick={() => {
            navigate('/dashboard/properties');
          }}
        />
      ) : (
        <>
          {/* Delete property */}
          {deleteModalOpen && (
            <ConfirmActionModal
              action={`delete this property`}
              onConfirmClick={() => {
                deleteProperty({ id: propertyId })
                  .unwrap()
                  .then(() => {
                    navigate('/dashboard/properties');
                  })
                  .catch((e) => {
                    toast.error(e?.data?.msg || 'Delete Failed');
                  });
              }}
              onCancel={() => {
                setDeleteModalOpen(false);
              }}
            />
          )}

          {/* Action buttons */}
          <FloatingButtons>
            <Button
              onClick={() => {
                navigate(`/dashboard/plans/new?propertyId=${propertyData?.id}`);
              }}
              title='Add Plan'
              wFull={false}
            />
            {propertyData?.owner && (
              <Button
                onClick={() => {
                  navigate(`/dashboard/owners/${propertyData?.owner?.id}`);
                }}
                title='Company Details'
                wFull={false}
                theme='secondary'
              />
            )}
            {/* Delete button commented out for now */}
            <Button
              onClick={() => {
                setDeleteModalOpen(true);
              }}
              theme='secondary'
              title='Delete Property'
              wFull={false}
            />
          </FloatingButtons>

          {/* Property Details Table */}
          <InfoCard title='Property Information'>
            <InfoGrid>
              <InfoField title='Name'>{propertyData?.name}</InfoField>
              <InfoField title='Status'>
                <StatusLabel status={propertyData?.status} />
              </InfoField>
              <InfoField title='ID' className='col-span-2 uppercase'>
                <CopyLink text={propertyData?.id} title='Property ID' />
              </InfoField>
            </InfoGrid>
            <InfoGridView infoFields={contactFields} />
            <InfoGridView infoFields={rateFields} />
            <InfoGridView infoFields={addressFields} />

            {/* Notes */}
            {propertyData?.notes ? (
              <InfoGrid>
                <InfoField title='Notes' className='col-span-4'>
                  {propertyData?.notes}
                </InfoField>
              </InfoGrid>
            ) : null}
          </InfoCard>

          {/* Lease Defaults */}
          <InfoCard title='Property-Wide Lease Defaults'>
            <InfoGridView infoFields={propertyDefaultsFields} />
          </InfoCard>

          {/* Owner Information */}
          {propertyData?.owner?.id ? (
            <InfoCard title='Owner Information'>
              <InfoGrid>
                <InfoField title='Name'>{propertyData?.owner?.name}</InfoField>
                <InfoField title='Status'>
                  {propertyData?.owner?.status}
                </InfoField>
                <InfoField title='ID' className='uppercase'>
                  <CopyLink text={propertyData?.owner?.id} title='Owner ID' />
                </InfoField>
              </InfoGrid>
              <InfoGridView infoFields={ownerContactFields} />
              <InfoGridView infoFields={ownerCommissionFields} />
              {propertyData?.owner?.notes ? (
                <InfoGrid>
                  <InfoField title='Notes' className='col-span-4'>
                    {propertyData?.owner?.notes}
                  </InfoField>
                </InfoGrid>
              ) : null}
            </InfoCard>
          ) : (
            <EmptyPage
              title='No Owner Information'
              subtitle='This property does not have an owner assigned.'
              PageIcon={FaBuildingColumns}
            />
          )}

          {/* Payment Information */}
          {propertyData?.paymentInformation ? (
            <InfoCard title='Payment Information'>
              <InfoGrid>
                <InfoField title='Currency'>
                  {propertyData?.paymentInformation?.currency}
                </InfoField>
                <InfoField title='Beneficiary Name'>
                  <CopyLink
                    text={propertyData?.paymentInformation?.beneficiary}
                    title='Beneficiary Name'
                  />
                </InfoField>
              </InfoGrid>
              <InfoGrid>
                <InfoField title='Beneficiary Account Number'>
                  <CopyLink
                    text={
                      propertyData?.paymentInformation?.beneficiaryAccountNumber
                    }
                    title='Beneficiary Account Number'
                  />
                </InfoField>
                <InfoField title='Beneficiary Routing Number (ABA)'>
                  <CopyLink
                    text={
                      propertyData?.paymentInformation?.beneficiaryRoutingNumber
                    }
                    title='Beneficiary Routing Number'
                  />
                </InfoField>
                <InfoField title='Beneficiary SWIFT Code'>
                  <CopyLink
                    text={
                      propertyData?.paymentInformation?.beneficiarySwiftCode
                    }
                    title='Beneficiary SWIFT Code'
                  />
                </InfoField>
              </InfoGrid>
              <InfoGrid>
                <InfoField title='Beneficiary Bank Name' className='col-span-4'>
                  <CopyLink
                    text={propertyData?.paymentInformation?.beneficiaryBankName}
                    title='Beneficiary Bank Name'
                  />
                </InfoField>
                <InfoField
                  title='Street Address 1'
                  className={
                    propertyData?.paymentInformation?.beneficiaryBankAddress
                      ?.streetAddress2
                      ? 'col-span-2'
                      : 'col-span-4'
                  }
                >
                  {
                    propertyData?.paymentInformation?.beneficiaryBankAddress
                      ?.streetAddress1
                  }
                </InfoField>
                {propertyData?.paymentInformation?.beneficiaryBankAddress
                  ?.streetAddress2 && (
                  <InfoField title='Street Address 2' className='col-span-2'>
                    {
                      propertyData?.paymentInformation?.beneficiaryBankAddress
                        ?.streetAddress2
                    }
                  </InfoField>
                )}
                <InfoField title='City'>
                  {
                    propertyData?.paymentInformation?.beneficiaryBankAddress
                      ?.city
                  }
                </InfoField>
                {propertyData?.paymentInformation?.beneficiaryBankAddress
                  ?.state && (
                  <InfoField title='State'>
                    {
                      propertyData?.paymentInformation?.beneficiaryBankAddress
                        ?.state
                    }
                  </InfoField>
                )}
                <InfoField title='Postal Code'>
                  {
                    propertyData?.paymentInformation?.beneficiaryBankAddress
                      ?.postalCode
                  }
                </InfoField>
                <InfoField title='Country'>
                  {
                    propertyData?.paymentInformation?.beneficiaryBankAddress
                      ?.country
                  }
                </InfoField>
              </InfoGrid>
            </InfoCard>
          ) : (
            <EmptyPage
              title='No Payment Information'
              subtitle='This property does not have payment information.'
              PageIcon={FaCreditCard}
            />
          )}

          {/* Documents shared for this property */}
          {propertyData?.documents?.length > 0 ? (
            <div className='flex flex-col gap-4'>
              <h2 className='text-lg font-medium'>
                Shared Documents from Owner
              </h2>
              <table className='w-full text-base'>
                <tbody className='flex flex-col rounded-md border border-gray-200 divide-y divide-gray-200'>
                  {propertyData?.documents?.map((doc, index) => (
                    <tr
                      key={index}
                      className='grid grid-cols-[1fr_auto] items-center px-4 gap-3'
                    >
                      <td className='py-2 text-base font-medium'>{doc.name}</td>
                      <td className='py-2'>
                        <Button
                          title='View Document'
                          theme='secondary'
                          onClick={() => {
                            window.open(doc.url, '_blank');
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <EmptyPage
              title='No Shared Documents'
              subtitle='There are no shared documents.'
              PageIcon={FaFile}
            />
          )}

          {/* Plans at this property */}
          {isEmpty(plansData) ? (
            <EmptyPage
              title='No Plans Under Property'
              subtitle='This property does not have any plans.'
            />
          ) : (
            <div className='flex flex-col gap-4'>
              <h2 className='text-lg font-medium'>Property Plans</h2>
              <PlansTable
                showMember={true}
                plans={plansData}
                setSort={setSort}
                sortVal={sortVal}
              />
            </div>
          )}
        </>
      )}
    </DashboardWrapperLayout>
  );
};

export default PropertyView;
