import React from 'react';
import { planStatusEnum } from 'common/utils/enums';
import StatusLabelView from 'common/components/status-label';

const StatusLabel = ({ status = 'In Review' }) => {
  // DATA INITIALIZATION
  let colorScheme = '';

  switch (status) {
    case planStatusEnum.PENDING:
      colorScheme = 'yellow';
      break;
    case planStatusEnum.ACTIVE:
      colorScheme = 'green';
      break;
    case planStatusEnum.COMPLETE:
      colorScheme = 'green';
      break;
    case planStatusEnum.REJECTED:
      colorScheme = 'red';
      break;
    case planStatusEnum.CANCELLED:
      colorScheme = 'red';
      break;
    case planStatusEnum.CREATED:
      colorScheme = 'gray';
      break;
    case planStatusEnum.REVIEW:
      colorScheme = 'yellow';
      break;
    default:
      colorScheme = 'gray';
  }

  return <StatusLabelView colorScheme={colorScheme} status={status} />;
};

export default StatusLabel;
