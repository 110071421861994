import React from 'react';
import { verificationStatusEnum } from 'common/utils/enums';
import StatusLabelView from 'common/components/status-label';

const StatusLabel = ({ status = false }) => {
  // DATA INITIALIZATION
  let colorScheme = '';
  let statusText = '';

  switch (status) {
    case verificationStatusEnum.VERIFIED:
      colorScheme = 'green';
      statusText = 'Verified';
      break;
    case verificationStatusEnum.NOT_VERIFIED:
      colorScheme = 'red';
      statusText = 'Not Verified';
      break;
    default:
      colorScheme = 'gray';
      statusText = 'Not Yet Verified';
  }

  return <StatusLabelView colorScheme={colorScheme} status={statusText} />;
};

export { StatusLabel };
