export const FormatAsCurrency = (amount, currency='USD') => {
  const formatted = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(amount);

  return formatted;
}

export default FormatAsCurrency;
