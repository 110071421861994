import { Flex, Text } from "@chakra-ui/react";
import { formatDateToHumanDate, formatDateToHumanTime } from "common/utils/formDateToHumanTime";
import { Icon } from "@chakra-ui/react";
import { FaCheck, FaUser } from "react-icons/fa6";

const ProgressIcon = ({ isBlurred, isCompleted, CardIcon, zIndex, className }) => {
  return (
    <span
      className={`items-center h-8 w-8 flex justify-center border border-gray-200 rounded-full ${zIndex} ${isCompleted ? 'bg-gray-200 text-gray-500' : 'bg-gray-100'} ${isBlurred && 'border-gray-300'} ${className}`}
    >
      <Icon
        className={`text-lg ${isBlurred && 'text-gray-500'}`}
        as={isCompleted ? FaCheck : CardIcon || FaUser}
        boxSize={4}
      />
    </span>
  ) 
}

const LogItem = ({ logItem }) => {
  return (
    <Flex className='w-full gap-1 items-start flex-col mb-4'>
      <Flex className='w-full gap-1 justify-between items-center'>
        <Text className='text-base font-medium capitalize'>
          {logItem.title}
        </Text>
        <Text className='text-sm text-gray-500 min-w-fit mr-2'>
          {formatDateToHumanTime(logItem.timestamp)}
        </Text>  
      </Flex>                      
      <Text className='text-md'>
        {logItem.description}
      </Text>
    </Flex> 
  )
}

const LogList = ({ logs, RenderItem }) => {
  return (
    <Flex className='w-full flex-col gap-1 py-1 col-span-4'>
      {logs?.map((logItem, index) => (
        <>
          <Flex key={index} className='w-full gap-4 items-start'>
            <Flex className='flex-col h-full items-center'>
              <ProgressIcon
                CardIcon={FaCheck}
                className='px-4 py-4 mt-1'
              />
              {/* Divider between log items */}
              {index < logs?.length - 1 && (
                <div className='h-full min-h-[1rem] mt-2 flex w-10 justify-center'>
                  <div className='h-full border-l-2 border-gray-200' />
                </div>
              )}
            </Flex>
            {
              RenderItem ? (
                <RenderItem logItem={logItem} />
              ) : (
                <LogItem logItem={logItem} />
              )
            }                               
          </Flex>                  
        </>
      ))}
    </Flex>
  );
}

export default LogList;
