import React from 'react';
import { documentStatusEnum } from 'common/utils/enums';
import StatusLabelView from 'common/components/status-label';

const StatusLabel = ({ status = 'none' }) => {
  // DATA INITIALIZATION
  let colorScheme = '';

  switch (status) {
    case documentStatusEnum.NONE:
      colorScheme = 'gray';
      break;
    case documentStatusEnum.UPLOADED:
      colorScheme = 'yellow';
      break;
    case documentStatusEnum.VERIFIED:
      colorScheme = 'green';
      break;
    case documentStatusEnum.REJECTED:
      colorScheme = 'red';
      break;
    default:
      colorScheme = 'gray';
  }

  return <StatusLabelView colorScheme={colorScheme} status={status} />;
};

export { StatusLabel };
