import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Button from 'common/components/button';
import StatusLabel from 'components/claims/status-label';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { useGetClaimQuery } from 'redux/api/ciosAdminApi/ciosAdminApi';
import {
  claimStatusEnum,
  claimTypeEnum,
  claimPaymentPreferenceEnum,
} from 'common/utils/enums';
import Toggle from 'common/components/toggle';
import {
  InfoCard,
  InfoField,
  InfoGrid,
  InfoGridView,
} from 'common/components/info-card';
import {
  formatDateToHumanDate,
  formatDateToHumanTime,
} from 'common/utils/formDateToHumanTime';
import { Flex, Icon, Text } from '@chakra-ui/react';
import LogPaymentModal from 'components/claims/log-payment-modal';
import LogList from 'common/components/admin-log';
import FormatAsCurrency from 'common/utils/formatAsCurrency';
import LogConfirmationModal from 'components/claims/log-confirmation-modal';
import FloatingButtons from 'common/components/floating-action-button';
import EmptyPage from 'common/components/empty-page';

// Set up rendering for progress log and payments log
const ProgressLog = ({ logItem }) => {
  return (
    <Flex className='w-full gap-1 items-center flex-col'>
      <Flex className='w-full gap-1 flex-col items-start'>
        <Text className='text-base font-medium capitalize'>
          {logItem.status}
        </Text>
        <Text className='text-sm text-gray-500 min-w-fit mr-2'>
          {formatDateToHumanTime(logItem.updatedAt)}
        </Text>
      </Flex>
    </Flex>
  );
};

const PaymentLog = ({ logItem }) => {
  return (
    <Flex className='w-full gap-1 items-start flex-col mb-2'>
      <Flex className='w-full gap-2 justify-between items-center'>
        <Text className='text-base font-medium capitalize'>
          {FormatAsCurrency(logItem.amount, logItem.currency)}{' '}
          {logItem.currency || 'USD'}
        </Text>
        <Text className='text-sm text-gray-500 min-w-fit mr-2'>
          {formatDateToHumanDate(logItem.date)}
        </Text>
      </Flex>
      <Flex className='w-full gap-3 flex-col items-start'>
        <Text className='text-md'>
          <span className='capitalize'>{logItem.paymentMethod}</span> Payment to{' '}
          <span className='capitalize'>{logItem.recipient}</span>
        </Text>
        {logItem.receipt && (
          <Button
            onClick={() => {
              if (logItem.receipt) {
                window.open(logItem.receipt, '_blank');
              } else {
                toast.error('No receipt available');
              }
            }}
            theme='secondary'
            title='View Receipt'
            wFull={false}
            className='mb-3'
          />
        )}
      </Flex>
    </Flex>
  );
};

const ConfirmationLog = ({ logItem }) => {
  return (
    <Flex className='w-full gap-1 items-start flex-col mb-2'>
      <Flex className='w-full gap-2 justify-between items-center'>
        <Text className='text-base font-medium capitalize'>
          {logItem.title}
        </Text>
        <Text className='text-sm text-gray-500 min-w-fit mr-2'>
          {formatDateToHumanDate(logItem.timestamp)}
        </Text>
      </Flex>
      <Flex className='w-full gap-3 justify-between items-center'>
        {logItem.fileUrl && (
          <Button
            onClick={() => {
              if (logItem.fileUrl) {
                window.open(logItem.fileUrl, '_blank');
              } else {
                toast.error('No documentation available');
              }
            }}
            theme='secondary'
            title='View Documentation'
            wFull={false}
            className='mb-3 mt-2'
          />
        )}
      </Flex>
    </Flex>
  );
};

const ClaimView = () => {
  const navigate = useNavigate();
  const { claimId } = useParams();
  const [logModal, setLogModal] = useState({ open: false, id: null });
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    id: null,
  });

  const {
    data: claimData,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
  } = useGetClaimQuery(claimId);

  // Turn confirmation into an array sorted by timestamp for ease of rendering
  let confirmationArray = Object.entries(claimData?.confirmation || {}).map(
    ([key, value]) => ({
      title:
        key === 'vacated'
          ? 'Unit Vacated'
          : key === 'current'
            ? 'Member Current'
            : 'Unit Re-Listed',
      timestamp: value.timestamp,
      fileUrl: value.fileUrl,
    })
  );

  // Sort only if the array is not empty
  if (confirmationArray.length > 0) {
    confirmationArray = [...confirmationArray].sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
  }

  // Sort logs by timestamp
  let statusChanges = claimData?.statusChanges || [];
  let claimPayments = claimData?.claimPayments || [];

  // Ensure statusChanges and claimPayments are shallow copies before sorting
  if (statusChanges.length > 0) {
    statusChanges = [...statusChanges].sort(
      (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
    );
  }
  if (claimPayments.length > 0) {
    claimPayments = [...claimPayments].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
  }

  const { userId: userData, planId: planData } = claimData || {};
  const { status: claimStatus } = claimData || {};

  const memberPaymentInfoFields = [
    {
      condition: !!claimData?.memberPayment?.stripeId, // Show only if stripeId exists
      title: 'Member Payment ID',
      value: (
        <Link
          onClick={() => {
            const url = `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}payments/${claimData?.memberPayment?.stripeId}`;
            window.open(url, '_blank');
          }}
          className='underline hover:opacity-50'
        >
          {claimData?.memberPayment?.stripeId}
        </Link>
      ),
    },
    {
      condition: !!claimData?.memberPayment?.deductible, // Show only if deductible exists
      title: 'Deductible',
      value: `$${claimData?.memberPayment?.deductible} ${planData?.lease?.currency}`,
    },
    {
      condition: !!claimData?.memberPayment?.nonEligibleCharges, // Show only if nonEligibleCharges exist
      title: 'Non-Eligible Charges',
      value: `$${claimData?.memberPayment?.nonEligibleCharges} ${planData?.lease?.currency}`,
    },
  ];

  const moveOutDatesInfoFields = [
    {
      condition: !!claimData?.review?.dates?.moveOutStart, // Show only if moveOutStart exists
      title: 'Approved Earliest Move Out Date',
      value: formatDateToHumanDate(claimData?.review?.dates?.moveOutStart),
    },
    {
      condition: !!claimData?.review?.dates?.moveOutEnd, // Show only if moveOutEnd exists
      title: 'Approved Latest Move Out Date',
      value: formatDateToHumanDate(claimData?.review?.dates?.moveOutEnd),
    },
    {
      condition: !!claimData?.submission?.confirmedDate, // Show only if confirmedDate exists
      title: 'Confirmed Move Out Date',
      value: formatDateToHumanDate(claimData?.submission?.confirmedDate),
    },
  ];

  return (
    <DashboardWrapperLayout
      title={`Claim Details`}
      buttons={
        <Toggle
          selected={'view'}
          editPath={`/dashboard/claims/${claimId}/edit`}
          viewPath={`/dashboard/claims/${claimId}`}
        />
      }
    >
      {isGetError ? (
        <EmptyPage
          title='Error Loading Claim'
          subtitle='There was an error loading the claim. Please reload the page and try again.'
          isError={isGetError}
          buttonText={'Reload Page'}
          onClick={() => window.location.reload()}
        />
      ) : isGetLoading ? (
        <EmptyPage
          title='Loading Claim'
          subtitle='Please wait while we load the claim details.'
          isLoading={isGetLoading}
        />
      ) : !claimData ? (
        <EmptyPage
          title='Claim Not Found'
          subtitle='The claim you are looking for does not exist.'
          isError={true}
          buttonText={'Go Back'}
          onClick={() => navigate('/dashboard/claims')}
        />
      ) : (
        <>
          {/* Action Buttons */}
          <FloatingButtons>
            {/* Only show log button if status is confirmed */}
            {claimStatus === claimStatusEnum.CONFIRMED && (
              <>
                <Button
                  title='Log Compensation Payment'
                  theme='primary'
                  wFull={false}
                  onClick={() => {
                    setLogModal({ open: true, id: claimId });
                  }}
                />
                <Button
                  title='Log Claim Confirmation'
                  theme='primary'
                  wFull={false}
                  onClick={() => {
                    setConfirmationModal({ open: true, id: claimId });
                  }}
                />
              </>
            )}
            <Button
              onClick={() => {
                navigate(`/dashboard/members/${userData.id}`);
              }}
              title='View Member Details'
              theme='secondary'
              wFull={false}
              className='px-6'
            />
            <Button
              onClick={() => {
                navigate(`/dashboard/plans/${planData.id}`);
              }}
              title='View Plan Details'
              theme='secondary'
              wFull={false}
              className='px-6'
            />
          </FloatingButtons>

          {/* Log Payment Modal */}
          {logModal.open && (
            <LogPaymentModal
              claimId={claimId}
              onCancel={() => {
                setLogModal({ open: false, id: null });
              }}
            />
          )}

          {/* Log Confirmation Modal */}
          {confirmationModal.open && (
            <LogConfirmationModal
              claimId={claimId}
              onCancel={() => {
                setConfirmationModal({ open: false, id: null });
              }}
            />
          )}

          {/* Logs */}
          <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-4 items-start'>
            <h2 className='sm:col-span-2 lg:col-span-3 font-medium text-lg'>
              Claim Progress Logs
            </h2>

            <InfoCard title='Claim Progress'>
              <Flex className='p-4'>
                <LogList logs={statusChanges} RenderItem={ProgressLog} />
              </Flex>
            </InfoCard>

            <InfoCard title='Claim Payments'>
              <InfoGrid small>
                <InfoField title='Total Payments'>
                  {FormatAsCurrency(
                    claimData?.claimPayments?.reduce(
                      (acc, curr) => acc + curr.amount,
                      0
                    ),
                    claimData?.planId?.lease?.currency
                  )}{' '}
                  {claimData?.planId?.lease?.currency || 'USD'}
                </InfoField>
                <InfoField title='Months Covered'>
                  {claimData?.claimPayments?.length} months
                </InfoField>
              </InfoGrid>
              {claimData?.claimPayments?.length > 0 && (
                <Flex className='p-4'>
                  <LogList logs={claimPayments} RenderItem={PaymentLog} />
                </Flex>
              )}
            </InfoCard>

            <InfoCard title='Claim Confirmation'>
              <Flex className='p-4'>
                {confirmationArray.length > 0 ? (
                  <LogList
                    logs={confirmationArray}
                    RenderItem={ConfirmationLog}
                  />
                ) : (
                  <Text className='text-sm text-gray-500'>
                    No confirmation logs submitted
                  </Text>
                )}
              </Flex>
            </InfoCard>
          </div>

          {/* Submitted Information */}
          <InfoCard title='Submitted Information'>
            {/* shared information for all claim types */}
            <InfoGrid>
              <InfoField title='Claim Number'>{claimData?.id}</InfoField>
              <InfoField title='Plan Number'>{planData?.id}</InfoField>
              <InfoField title='Lease'>
                {planData?.lease?.address?.streetAddress1},{' '}
                {planData?.lease?.address?.city}
              </InfoField>
              <InfoField title='Claim Type'>
                {claimData?.claimType === claimTypeEnum.CANCEL_ANYTIME
                  ? 'Cancel Lease'
                  : 'Early Move Out'}
              </InfoField>
            </InfoGrid>

            <InfoGrid>
              <InfoField title='Earliest Move Out Date'>
                {formatDateToHumanDate(claimData?.submission?.moveOutStart)}
              </InfoField>
              <InfoField title='Latest Move Out Date'>
                {formatDateToHumanDate(claimData?.submission?.moveOutEnd)}
              </InfoField>
              <InfoField title='Payment Preference'>
                {claimData?.submission?.paymentPreference ===
                claimPaymentPreferenceEnum.DIRECT
                  ? 'Direct'
                  : 'Reimbursement'}
              </InfoField>
            </InfoGrid>

            <InfoGrid>
              <InfoField title='Reason for Move Out' className='col-span-4'>
                {claimData?.submission?.moveReason}
              </InfoField>
            </InfoGrid>
          </InfoCard>

          {/* Status and Results */}
          <InfoCard title='Claim Review'>
            <InfoGrid>
              <InfoField title='Status'>
                <StatusLabel status={claimStatus} />
              </InfoField>
            </InfoGrid>
            <InfoGridView infoFields={moveOutDatesInfoFields} />
            {claimData?.review?.reason ? (
              <InfoGrid>
                <InfoField title='Review Notes' className='col-span-4'>
                  {claimData?.review?.reason}
                </InfoField>
              </InfoGrid>
            ) : null}
          </InfoCard>

          {/* Member Payment */}
          {/* Only show if memberPayment exists */}
          {claimData?.memberPayment ? (
            <InfoCard title='Member Payment'>
              <InfoGridView infoFields={memberPaymentInfoFields} />
            </InfoCard>
          ) : (
            <EmptyPage
              title='No Member Payment Required'
              subtitle='This claim does not have any member payment required.'
            />
          )}
        </>
      )}
    </DashboardWrapperLayout>
  );
};

export default ClaimView;
