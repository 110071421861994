import { useParams } from 'react-router-dom';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import * as Yup from 'yup';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { Form, Formik } from 'formik';
import AutoCompleteAddress from 'common/components/autoaddress';
import Input from 'common/components/input';
import SelectInput from 'common/components/select-input';
import {
  propertyKindEnum,
  propertyStatusEnum,
  ownerKindEnum,
} from 'common/utils/enums';
import {
  booleanOptions,
  planCurrencyOptions,
  propertyKindOptions,
  propertyStatusOptions,
} from 'common/utils/selectOptions';
import FormError from 'common/components/form-error';
import {
  useFindOwnersQuery,
  useGetPropertyQuery,
  useNewPropertyMutation,
  useUpdatePropertyMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from 'common/components/button';
import { PhoneNumberInput } from 'common/components/phone-input';
import 'react-international-phone/style.css';
import Toggle from 'common/components/toggle';
import TextAreaInput from 'common/components/text-area-input';
import { Tag } from '@chakra-ui/react';
import { currency } from 'common/utils/enums';
import { isEmpty } from 'utils/isEmpty';
import FloatingButtons from 'common/components/floating-action-button';
import EmptyPage from 'common/components/empty-page';

const PropertyEdit = () => {
  const { propertyId } = useParams();
  const isNew = !propertyId;
  const navigate = useNavigate();
  const {
    data: propertyData,
    isError: isGetError,
    error: getError,
    isLoading: isLoadingProperty,
    isFetching: isFetchingProperty,
  } = useGetPropertyQuery(propertyId, { skip: isNew });
  const [updateProperty, { isLoading: isUpdatingProperty }] =
    useUpdatePropertyMutation();
  const [
    createProperty,
    { isLoading: isCreateLoading, isFetching: isCreateFetching },
  ] = useNewPropertyMutation();
  const {
    data: ownersData,
    isError: isFindOwnersError,
    isLoading: findOwnersLoading,
  } = useFindOwnersQuery({
    limit: '200',
    sort: '-id',
  });

  const createOwnerOptionMenu = (obj) => {
    if (isEmpty(obj?.data)) return [];

    return obj?.data?.map(({ id, name, kind, ...res }) => ({
      label: (
        <div className='w-full flex gap-2 justify-between'>
          <div className='text-md font-medium flex gap-2 items-center'>
            {name || 'Owner'}
            <Tag
              colorScheme={kind === ownerKindEnum.MANAGER ? 'blue' : 'green'}
              size='sm'
            >
              {kind === ownerKindEnum.MANAGER ? 'Manager' : 'Owner'}
            </Tag>
          </div>
          <div className='text-md font-normal text-gray-500'>{id}</div>
        </div>
      ),
      value: id,
    }));
  };

  // Logging
  // useEffect(() => {
  //   if (ownersData) {
  //     console.log(ownersData);
  //   }
  // }, [ownersData]);

  const {
    streetAddress1,
    streetAddress2,
    city,
    state,
    country,
    postalCode,
    neighborhood,
  } = propertyData?.address || {
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    neighborhood: '',
    postalCode: '',
    state: '',
    country: '',
  };

  const queryParams = new window.URLSearchParams(window.location.search);
  const ownerId = queryParams.get('ownerId');

  if (isGetError) {
    toast.error(getError?.data?.msg || 'Failed to fetch property details');
    return (
      <DashboardWrapperLayout title={'Failed to Fetch Property Details'}>
        <EmptyPage
          isError
          title='Failed to fetch property details'
          subtitle={getError?.data?.msg || 'Failed to fetch property details'}
          buttonText={'Reload Page'}
          onClick={() => window.location.reload()}
        />
      </DashboardWrapperLayout>
    );
  }

  if (!isNew && !propertyData) {
    return (
      <DashboardWrapperLayout title={'Loading Property Details'}>
        <EmptyPage
          isLoading
          title='Loading Property Details'
          subtitle='Please wait while we load the Property details.'
        />
      </DashboardWrapperLayout>
    );
  }

  return (
    <Formik
      onSubmit={(values) => {
        let valuesCp = JSON.parse(JSON.stringify(values));

        if (!values.owner) {
          valuesCp.owner = null;
        }

        if (propertyId) {
          updateProperty({
            id: propertyId,
            ...valuesCp,
          })
            .unwrap()
            .then(() => {
              navigate(`/dashboard/properties/${propertyId}`);
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Update Failed');
            });
        } else {
          createProperty({
            ...valuesCp,
          })
            .unwrap()
            .then((prop) => {
              navigate(`/dashboard/properties/${prop?.id}`);
            });
        }
      }}
      initialValues={{
        name: propertyData?.name,
        contactName: propertyData?.contactName || '',
        owner: ownerId || propertyData?.owner?.id || propertyData?.owner || '',
        phone: propertyData?.phone || '',
        email: propertyData?.email || '',
        website: propertyData?.website || '',
        notes: propertyData?.notes || '',
        status: propertyData?.status || propertyStatusEnum.REQUIRED,
        kind: propertyData?.kind || propertyKindEnum.OTHER,
        riskScore: propertyData?.riskScore || 0,
        cancelAnytimeRate: propertyData?.cancelAnytimeRate,
        leaveAnytimeRate: propertyData?.leaveAnytimeRate,
        address: {
          streetAddress1,
          streetAddress2,
          city,
          postalCode,
          neighborhood,
          state,
          country,
        },
        paymentInformation: {
          currency: propertyData?.paymentInformation?.currency || currency.USD,
          beneficiary: propertyData?.paymentInformation?.beneficiary || '',
          beneficiaryAccountNumber:
            propertyData?.paymentInformation?.beneficiaryAccountNumber || '',
          beneficiaryRoutingNumber:
            propertyData?.paymentInformation?.beneficiaryRoutingNumber || '',
          beneficiarySwiftCode:
            propertyData?.paymentInformation?.beneficiarySwiftCode || '',
          beneficiaryBankName:
            propertyData?.paymentInformation?.beneficiaryBankName || '',
          beneficiaryBankAddress: {
            streetAddress1:
              propertyData?.paymentInformation?.beneficiaryBankAddress
                ?.streetAddress1 || '',
            streetAddress2:
              propertyData?.paymentInformation?.beneficiaryBankAddress
                ?.streetAddress2 || '',
            city:
              propertyData?.paymentInformation?.beneficiaryBankAddress?.city ||
              '',
            postalCode:
              propertyData?.paymentInformation?.beneficiaryBankAddress
                ?.postalCode || '',
            neighborhood:
              propertyData?.paymentInformation?.beneficiaryBankAddress
                ?.neighborhood || '',
            state:
              propertyData?.paymentInformation?.beneficiaryBankAddress?.state ||
              '',
            country:
              propertyData?.paymentInformation?.beneficiaryBankAddress
                ?.country || '',
          },
        },
        propertyDefaults: {
          terminationPolicy: {
            noticePeriod:
              propertyData?.propertyDefaults?.terminationPolicy?.noticePeriod ||
              0,
            penalty:
              propertyData?.propertyDefaults?.terminationPolicy?.penalty || 0,
            buyoutMonths:
              propertyData?.propertyDefaults?.terminationPolicy?.buyoutMonths ||
              0,
          },
          furnished: propertyData?.propertyDefaults?.furnished || false,
        },
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Building name is required'),
        contactName: Yup.string(),
        owner: Yup.string().test(
          'is-empty-or-24-chars',
          'Must be an empty string or exactly 24 characters long',
          (value) =>
            value === '' ||
            value === null ||
            value === undefined ||
            (value && value.length === 24)
        ),
        email: Yup.string(),
        phone: Yup.string(),
        website: Yup.string(),
        notes: Yup.string(),
        riskScore: Yup.number(),
        cancelAnytimeRate: Yup.number('Default rate must be a number'),
        leaveAnytimeRate: Yup.number('Default rate must be a number'),
        status: Yup.string().required('Status is required'),
        kind: Yup.string().required('Property type is required'),
        address: Yup.object().shape({
          streetAddress1: Yup.string().required('Street Address 1 is required'),
          streetAddress2: Yup.string(),
          city: Yup.string().required('City is required'),
          postalCode: Yup.string().required('Postal Code is required'),
          state: Yup.string().required('State is required'),
          country: Yup.string().required('Country is required'),
        }),
        paymentInformation: Yup.object().shape({
          currency: Yup.string().required('Currency is required'),
          beneficiary: Yup.string().required('Beneficiary Name is required'),
          beneficiaryAccountNumber: Yup.string().required(
            'Beneficiary Account Number is required'
          ),
          beneficiaryRoutingNumber: Yup.string().required(
            'Beneficiary Routing Number is required'
          ),
          beneficiarySwiftCode: Yup.string().required(
            'Beneficiary Swift Code is required'
          ),
          beneficiaryBankName: Yup.string().required(
            'Beneficiary Bank Name is required'
          ),
          beneficiaryBankAddress: Yup.object().shape({
            streetAddress1: Yup.string().required(
              'Street Address 1 is required'
            ),
            streetAddress2: Yup.string(),
            city: Yup.string().required('City is required'),
            postalCode: Yup.string().required('Postal Code is required'),
            state: Yup.string().required('State is required'),
            country: Yup.string().required('Country is required'),
          }),
          propertyDefaults: Yup.object().shape({
            terminationPolicy: Yup.object().shape({
              noticePeriod: Yup.number(),
              penalty: Yup.number(),
              buyoutMonths: Yup.number(),
            }),
            furnished: Yup.boolean(),
          }),
        }),
      })}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        isValid,
        values,
        setFieldValue,
        dirty: isDirty,
      }) => {
        return (
          <Form>
            <DashboardWrapperLayout
              title={propertyData?.name || 'Property Details'}
              buttons={
                propertyId ? (
                  <Toggle
                    selected={'edit'}
                    editPath={`/dashboard/properties/${propertyData?.id}/edit`}
                    viewPath={`/dashboard/properties/${propertyData?.id}`}
                  />
                ) : null
              }
            >
              <>
                {/* Action Buttons */}
                <FloatingButtons
                  button={
                    <Button
                      disabled={!isValid || !isDirty}
                      onClick={handleSubmit}
                      title='Save Changes'
                      wFull={false}
                    />
                  }
                />

                {/* Property Fields Table */}
                <InfoCard title='Property Information'>
                  <InfoGrid>
                    <InfoField title='Name' className='col-span-2'>
                      <Input
                        placeholderText='Name'
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='name'
                      />
                      <FormError name='name' />
                    </InfoField>
                    <InfoField
                      title='Status'
                      className='col-span-2 sm:col-span-1'
                    >
                      <SelectInput
                        options={propertyStatusOptions}
                        placeholder='Select Status'
                        name='status'
                        value={propertyStatusOptions.find(
                          (status) => status.value === values.status
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('status', selectedOption?.value || '');
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField
                      title='Contact Name'
                      className='col-span-2 sm:col-span-1'
                    >
                      <Input
                        placeholderText='Contact Name'
                        value={values.contactName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='contactName'
                      />
                      <FormError name='contactName' />
                    </InfoField>
                    <InfoField title='Contact Phone' className='col-span-2'>
                      <PhoneNumberInput
                        name='phone'
                        value={values.phone}
                        onChange={(e) => {
                          setFieldValue('phone', e);
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='phone' />
                    </InfoField>
                    <InfoField title='Contact Email' className='col-span-2'>
                      <Input
                        placeholderText='Email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='email'
                      />
                      <FormError name='email' />
                    </InfoField>
                    <InfoField title='Website' className='col-span-2'>
                      <Input
                        placeholderText='website'
                        type='website'
                        value={values.website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='website'
                      />
                      <FormError name='website' />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField
                      title='Cancel Anytime Default Rate'
                      className='col-span-2 sm:col-span-1'
                    >
                      <Input
                        type='number'
                        placeholderText='Cancel Anytime Default Risk'
                        value={values.cancelAnytimeRate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='cancelAnytimeRate'
                      />
                      <FormError name='cancelAnytimeRate' />
                    </InfoField>
                    <InfoField
                      title='Leave Anytime Default Rate'
                      className='col-span-2 sm:col-span-1'
                    >
                      <Input
                        type='number'
                        placeholderText='Leave Anytime Default Risk'
                        value={values.leaveAnytimeRate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='leaveAnytimeRate'
                      />
                      <FormError name='leaveAnytimeRate' />
                    </InfoField>
                    <InfoField
                      title='Risk Score'
                      className='col-span-2 sm:col-span-1'
                    >
                      <Input
                        type='number'
                        placeholderText='Risk Score'
                        value={values.riskScore}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='riskScore'
                      />
                      <FormError name='riskScore' />
                    </InfoField>
                    <InfoField
                      title='Property Type'
                      className='col-span-2 sm:col-span-1'
                    >
                      <SelectInput
                        options={propertyKindOptions}
                        placeholder='Select Type'
                        name='kind'
                        value={propertyKindOptions.find(
                          (kind) => kind.value === values.kind
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('kind', selectedOption?.value || '');
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField
                      title='Autocomplete Address'
                      className='col-span-2 md:col-span-4'
                    >
                      <AutoCompleteAddress
                        onAddressChange={(newAddress) => {
                          setFieldValue(
                            'address.streetAddress1',
                            newAddress.streetAddress1 || ''
                          );
                          setFieldValue(
                            'address.streetAddress2',
                            newAddress.streetAddress2 || ''
                          );
                          setFieldValue('address.city', newAddress.city || '');
                          setFieldValue(
                            'address.state',
                            newAddress.state || ''
                          );
                          setFieldValue(
                            'address.country',
                            newAddress.country || ''
                          );
                          setFieldValue(
                            'address.postalCode',
                            newAddress.zipCode || ''
                          );
                        }}
                      />
                    </InfoField>
                    <InfoField title='Street Address 1' className='col-span-2'>
                      <Input
                        placeholderText='Street Address 1'
                        value={values.address.streetAddress1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='address.streetAddress1'
                      />
                      <FormError name='address.streetAddress1' />
                    </InfoField>
                    <InfoField title='Street Address 2' className='col-span-2'>
                      <Input
                        placeholderText='Street Address 2'
                        value={values.address.streetAddress2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='address.streetAddress2'
                      />
                      <FormError name='address.streetAddress2' />
                    </InfoField>
                    <InfoField title='City'>
                      <Input
                        placeholderText='City'
                        value={values.address.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='address.city'
                      />
                      <FormError name='address.city' />
                    </InfoField>
                    <InfoField title='State'>
                      <Input
                        placeholderText='State'
                        value={values.address.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='address.state'
                      />
                      <FormError name='address.state' />
                    </InfoField>
                    <InfoField title='Postal Code'>
                      <Input
                        placeholderText='Postal Code'
                        value={values.address.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='address.postalCode'
                      />
                      <FormError name='address.postalCode' />
                    </InfoField>
                    <InfoField title='Country'>
                      <Input
                        placeholderText='Country'
                        value={values.address.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='address.country'
                      />
                      <FormError name='address.country' />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Notes' className='col-span-4'>
                      <TextAreaInput
                        placeholderText='notes'
                        type='notes'
                        value={values.notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='notes'
                      />
                      <FormError name='notes' />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>

                {/* Lease Defaults */}
                <InfoCard title='Property-Wide Lease Defaults'>
                  <InfoGrid>
                    <InfoField
                      title='Default Notice Period'
                      className='col-span-2 sm:col-span-1'
                    >
                      <Input
                        type='number'
                        placeholderText='Notice Period'
                        value={
                          values.propertyDefaults.terminationPolicy.noticePeriod
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='propertyDefaults.terminationPolicy.noticePeriod'
                        suffix='days'
                      />
                      <FormError name='propertyDefaults.terminationPolicy.noticePeriod' />
                    </InfoField>
                    <InfoField
                      title='Default Termination Penalty'
                      className='col-span-2 sm:col-span-1'
                    >
                      <Input
                        type='number'
                        placeholderText='Termination Penalty'
                        value={
                          values.propertyDefaults.terminationPolicy.penalty
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='propertyDefaults.terminationPolicy.penalty'
                        prefix='$'
                        suffix={values?.paymentInformation?.currency}
                      />
                      <FormError name='propertyDefaults.terminationPolicy.penalty' />
                    </InfoField>
                    <InfoField
                      title='Default Lease Buyout'
                      className='col-span-2 sm:col-span-1'
                    >
                      <Input
                        type='number'
                        placeholderText='Lease Buyout'
                        value={
                          values.propertyDefaults.terminationPolicy.buyoutMonths
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='propertyDefaults.terminationPolicy.buyoutMonths'
                        suffix='months'
                      />
                      <FormError name='propertyDefaults.terminationPolicy.buyoutMonths' />
                    </InfoField>
                    <InfoField
                      title='Furnished by Default?'
                      className='col-span-2 sm:col-span-1'
                    >
                      <SelectInput
                        options={booleanOptions}
                        placeholder='Select Option'
                        name='propertyDefaults.furnished'
                        value={booleanOptions.find(
                          (status) =>
                            status.value === values.propertyDefaults.furnished
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'propertyDefaults.furnished',
                            selectedOption?.value || false
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='propertyDefaults.furnished' />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>

                {/* Owner Information */}
                <InfoCard title='Owner Information'>
                  <InfoGrid>
                    <InfoField
                      title='Company'
                      className='col-span-2 md:col-span-4'
                    >
                      <SelectInput
                        options={createOwnerOptionMenu(ownersData)}
                        placeholder='Select Company'
                        name='owner'
                        value={createOwnerOptionMenu(ownersData).find(
                          (owner) => owner.value === values.owner
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('owner', selectedOption?.value || '');
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='owner' />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>

                {/* Payment Information */}
                <InfoCard title='Payment Information'>
                  <InfoGrid>
                    <InfoField
                      title='Currency'
                      className='col-span-2 sm:col-span-1'
                    >
                      <SelectInput
                        options={planCurrencyOptions}
                        placeholder='Select Currency'
                        name='paymentInformation.currency'
                        value={planCurrencyOptions.find(
                          (currency) =>
                            currency.value ===
                            values.paymentInformation.currency
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'paymentInformation.currency',
                            selectedOption?.value || ''
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      <FormError name='paymentInformation.currency' />
                    </InfoField>
                    <InfoField title='Beneficiary Name' className='col-span-2'>
                      <Input
                        placeholderText='Beneficiary'
                        value={values.paymentInformation.beneficiary}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='paymentInformation.beneficiary'
                      />
                      <FormError name='paymentInformation.beneficiary' />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField
                      title='Beneficiary Account Number'
                      className='col-span-2 sm:col-span-1'
                    >
                      <Input
                        placeholderText='Account Number'
                        value={
                          values.paymentInformation.beneficiaryAccountNumber
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='paymentInformation.beneficiaryAccountNumber'
                      />
                      <FormError name='paymentInformation.beneficiaryAccountNumber' />
                    </InfoField>
                    <InfoField
                      title='Beneficiary Routing Number (ABA)'
                      className='col-span-2 sm:col-span-1'
                    >
                      <Input
                        placeholderText='Routing Number'
                        value={
                          values.paymentInformation.beneficiaryRoutingNumber
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='paymentInformation.beneficiaryRoutingNumber'
                      />
                      <FormError name='paymentInformation.beneficiaryRoutingNumber' />
                    </InfoField>
                    <InfoField
                      title='Beneficiary Swift Code'
                      className='col-span-2 sm:col-span-1'
                    >
                      <Input
                        placeholderText='Swift Code'
                        value={values.paymentInformation.beneficiarySwiftCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='paymentInformation.beneficiarySwiftCode'
                      />
                      <FormError name='paymentInformation.beneficiarySwiftCode' />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField
                      title='Beneficiary Bank Name'
                      className='col-span-2 md:col-span-4'
                    >
                      <Input
                        placeholderText='Bank Name'
                        value={values.paymentInformation.beneficiaryBankName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='paymentInformation.beneficiaryBankName'
                      />
                      <FormError name='paymentInformation.beneficiaryBankName' />
                    </InfoField>
                    <InfoField
                      title='Autocomplete Beneficiary Bank Address'
                      className='col-span-2 md:col-span-4'
                    >
                      <AutoCompleteAddress
                        onAddressChange={(newAddress) => {
                          setFieldValue(
                            'paymentInformation.beneficiaryBankAddress.streetAddress1',
                            newAddress.streetAddress1 || ''
                          );
                          setFieldValue(
                            'paymentInformation.beneficiaryBankAddress.streetAddress2',
                            newAddress.streetAddress2 || ''
                          );
                          setFieldValue(
                            'paymentInformation.beneficiaryBankAddress.city',
                            newAddress.city || ''
                          );
                          setFieldValue(
                            'paymentInformation.beneficiaryBankAddress.state',
                            newAddress.state || ''
                          );
                          setFieldValue(
                            'paymentInformation.beneficiaryBankAddress.country',
                            newAddress.country || ''
                          );
                          setFieldValue(
                            'paymentInformation.beneficiaryBankAddress.postalCode',
                            newAddress.zipCode || ''
                          );
                        }}
                      />
                    </InfoField>
                    <InfoField title='Street Address 1' className='col-span-2'>
                      <Input
                        placeholderText='Street Address 1'
                        value={
                          values.paymentInformation.beneficiaryBankAddress
                            .streetAddress1
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='paymentInformation.beneficiaryBankAddress.streetAddress1'
                      />
                      <FormError name='paymentInformation.beneficiaryBankAddress.streetAddress1' />
                    </InfoField>
                    <InfoField title='Street Address 2' className='col-span-2'>
                      <Input
                        placeholderText='Street Address 2'
                        value={
                          values.paymentInformation.beneficiaryBankAddress
                            .streetAddress2
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='paymentInformation.beneficiaryBankAddress.streetAddress2'
                      />
                      <FormError name='paymentInformation.beneficiaryBankAddress.streetAddress2' />
                    </InfoField>
                    <InfoField title='City'>
                      <Input
                        placeholderText='City'
                        value={
                          values.paymentInformation.beneficiaryBankAddress.city
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='paymentInformation.beneficiaryBankAddress.city'
                      />
                      <FormError name='paymentInformation.beneficiaryBankAddress.city' />
                    </InfoField>
                    <InfoField title='State'>
                      <Input
                        placeholderText='State'
                        value={
                          values.paymentInformation.beneficiaryBankAddress.state
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='paymentInformation.beneficiaryBankAddress.state'
                      />
                      <FormError name='paymentInformation.beneficiaryBankAddress.state' />
                    </InfoField>
                    <InfoField title='Postal Code'>
                      <Input
                        placeholderText='Postal Code'
                        value={
                          values.paymentInformation.beneficiaryBankAddress
                            .postalCode
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='paymentInformation.beneficiaryBankAddress.postalCode'
                      />
                      <FormError name='paymentInformation.beneficiaryBankAddress.postalCode' />
                    </InfoField>
                    <InfoField title='Country'>
                      <Input
                        placeholderText='Country'
                        value={
                          values.paymentInformation.beneficiaryBankAddress
                            .country
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='paymentInformation.beneficiaryBankAddress.country'
                      />
                      <FormError name='paymentInformation.beneficiaryBankAddress.country' />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>
              </>
            </DashboardWrapperLayout>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PropertyEdit;
