import { useState } from 'react';
import { toast } from 'react-toastify';
import { AiOutlineDelete } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import Button from 'common/components/button';
import StatusLabel from 'components/owners/status-label';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { isEmpty } from 'utils/isEmpty';
import {
  useGetOwnerQuery,
  useDeleteOwnerMutation,
  useFindPropertyQuery,
  useClaimAccountOwnerReqMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import Toggle from 'common/components/toggle';
import { InfoCard, InfoField, InfoGrid, InfoGridView } from 'common/components/info-card';
import { formatPhoneNumber } from 'react-phone-number-input';
import PropertiesTable from 'components/properties/properties-table';
import ConfirmActionModal from 'common/components/confirm-action-modal';
import FloatingButtons from 'common/components/floating-action-button';
import EmptyPage from 'common/components/empty-page';
import CopyLink from 'common/components/copy-link';

function ClaimAccountArea({ userId }) {
  const [claimAccount, { isLoading }] = useClaimAccountOwnerReqMutation();
  const [claimUrl, setClaimUrl] = useState('');
  return (
    <>
      <Button
        title='Send Activation Email'
        disabled={isLoading}
        onClick={() => {
          claimAccount({ id: userId })
            .unwrap()
            .then(({ url }) => {
              setClaimUrl(url);
              toast.success('Activation link sent to owner');
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Send failed');
            });
        }}
        wFull={false}
      />
      {claimUrl && (
        <Button
          title='Copy Activation Link'
          wFull={false}
          onClick={() => {
            navigator.clipboard.writeText(claimUrl);
            toast.success('Activation link copied to clipboard');
          }}
          theme='secondary'
        />
      )}
    </>
  );
}

const OwnerView = () => {
  const navigate = useNavigate();
  const { ownerId } = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const {
    data: ownerData,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
    isFetching: isGetFetching,
  } = useGetOwnerQuery(ownerId);
  const [
    deleteOwner,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
    // { isLoading: updatePlanLoading },
  ] = useDeleteOwnerMutation();

  // Find properties of owner
  const {
    data: propertiesQuery,
    isError: isGetPropertyError,
    error: getPropertyError,
    isLoading: findPropertiesLoading,
  } = useFindPropertyQuery({
    limit: 200,
    owner: ownerId,
  });

  // Set up fields for info grids
  const ownerContactInfoFields = [
    {
      condition: !!ownerData?.email, // Show only if email exists
      title: 'Email',
      className: 'col-span-2',
      value: (
        <CopyLink
          text={ownerData?.email}
          title='Owner Email'
        />
      ),
    },
    {
      condition: !!ownerData?.phone, // Show only if phone exists
      title: 'Phone',
      value: formatPhoneNumber(ownerData?.phone),
    },
    {
      condition: !!ownerData?.website, // Show only if website exists
      title: 'Website',
      value: ownerData?.website,
    },
  ];

  const ownerRevenueInfoFields = [
    {
      condition: ownerData?.revShareRate !== undefined, // Show even if 0
      title: 'Rev Share Rate',
      value: `${(ownerData?.revShareRate * 100).toFixed(2) || '0'}%`,
    },
    {
      condition: ownerData?.flatCommission !== undefined, // Show even if 0
      title: 'Flat Commission',
      value: `$${
        ownerData?.flatCommission ? ownerData?.flatCommission.toFixed(2) : '0.00'
      } Per Plan`,
    },
  ];

  return (
    <DashboardWrapperLayout
      title={ownerData?.name || 'Owner Details'}
      buttons={
        <Toggle
          selected={'view'}
          editPath={`/dashboard/owners/${ownerData?.id}/edit`}
          viewPath={`/dashboard/owners/${ownerData?.id}`}
        />
      }
    >
      {isGetError ? (
        <EmptyPage
          title='Error Loading Owner'
          subtitle={
            getError?.data?.msg ||
            'Failed to load owner details. Please try again.'
          }
          buttonText={'Reload Page'}
          onClick={() => {
            window.location.reload();
          }}
          isError
        />
      ) : isGetLoading || isGetFetching ? (
        <EmptyPage
          title='Loading Owner Details'
          subtitle='Please wait while we load the owner details.'
          isLoading
        />
      ) : (
        <>
          {/* Delete function */}
          {deleteModalOpen && (
            <ConfirmActionModal
              action={`delete this company`}
              onConfirmClick={() => {
                deleteOwner({ id: ownerId })
                  .unwrap()
                  .then(() => {
                    navigate('/dashboard/owners');
                  })
                  .catch((e) => {
                    toast.error(e?.data?.msg || 'Delete Failed');
                  });
              }}
              onCancel={() => {
                setDeleteModalOpen(false);
              }}
            />
          )}

          {/* Action buttons */}
          <FloatingButtons>
            <Button
              title='Add Property'
              wFull={false}
              onClick={() =>
                navigate(`/dashboard/properties/new?ownerId=${ownerData?.id}`)
              }
            />
            <ClaimAccountArea userId={ownerData?.id} />            
            <Button
              title='Delete Owner'
              onClick={() => {
                setDeleteModalOpen(true);
              }}
              theme='secondary'
              wFull={false}
            />            
          </FloatingButtons>

          {/* Owner Details Table */}
          <InfoCard title='Company Information'>
            <InfoGrid>
              <InfoField title='Name'>{ownerData?.name}</InfoField>
              <InfoField title='ID'>
                <CopyLink
                  text={ownerData?.id}
                  title='Owner ID'
                  />
              </InfoField>
              <InfoField title='Status'>
                <StatusLabel status={ownerData?.status} />
              </InfoField>
            </InfoGrid>
            <InfoGridView infoFields={ownerContactInfoFields} />
            <InfoGridView infoFields={ownerRevenueInfoFields} />            
            {ownerData?.notes ? (
              <InfoGrid>
                <InfoField title='Notes' className='col-span-4'>
                  {ownerData?.notes}
                </InfoField>
              </InfoGrid>
            ) : null}
          </InfoCard>

          {/* Owner's Properties Table */}
          {isEmpty(propertiesQuery?.data) ? (
            <EmptyPage
              title='No Properties Found'
              description='This owner has no properties enrolled.'
            />
          ) : (
            <div className='flex flex-col gap-4'>
              <h2 className='text-lg font-medium'>
                Properties
              </h2>
              <PropertiesTable properties={propertiesQuery?.data} />
            </div>
          )}
        </>
      )}
    </DashboardWrapperLayout>
  );
};

export default OwnerView;
