import React from 'react';
import { rentalTypeEnum } from 'utils/enums';
import StatusLabelView from 'common/components/status-label';

const RentalType = ({ rentalType = 'Apartment' }) => {

  // No separate color scheme for rental type right now

  return (
    <StatusLabelView
      status={rentalType}
    />
  );
};

export default RentalType;
