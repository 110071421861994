import Button from "common/components/button";
import Input from "common/components/input";

const SearchBar = ({ placeholderText='Search', showSearch=true, value, onSearch, buttonText, onClick }) => {
  return (
    <div className="w-full flex gap-3 justify-between items-center max-w-sm">
      {showSearch ? (
        <Input
          placeholderText={placeholderText}
          value={value}
          onChange={onSearch}
          name="textFilter"
          className='mobile:shadow-md'
        />
      ) : null}
      {onClick ? (
        <Button
          title={buttonText}
          onClick={onClick}
          wFull={false}
          className="px-6 mobile:shadow-md z-30 mobile:fixed mobile:right-5 mobile:bottom-6 mobile:max-w-screen"
        />
      ) : null}
    </div>
  );
};

export default SearchBar;
