import { Icon } from '@chakra-ui/react';
import { IoIosMenu } from "react-icons/io";

const HamburgerMenu = ({ isMenuOpen, onClick }) => {
  // Deprecating the old animated menu button because it's frustrating to work with
  // return (
  //   <div
  //     className={`relative flex h-6 w-7 flex-col items-center justify-center gap-1.5 p-1 rounded-md ${
  //       isMenuOpen ? 'bg-transparent' : 'bg-primary-light'
  //     } transition-all`}
  //   >
  //     <input
  //       type="checkbox"
  //       defaultChecked={isMenuOpen}
  //       onClick={onClick}
  //       className="absolute top-0 left-0 h-full w-full hidden cursor-pointer"
  //     />
  //     <span
  //       className={`block h-[2px] w-5 bg-gray-500 rounded transform transition-transform duration-500 ${
  //         isMenuOpen ? 'rotate-45 translate-y-[10px]' : ''
  //       }`}
  //     />
  //     <span
  //       className={`block h-[2px] w-5 bg-gray-500 rounded transition-opacity transform duration-500 ${
  //         isMenuOpen ? 'hidden' : ''
  //       }`}
  //     />
  //     <span
  //       className={`block h-[2px] w-5 bg-gray-500 rounded transform transition-transform duration-500 ${
  //         isMenuOpen ? '-rotate-45 -translate-y-[10px]' : ''
  //       }`}
  //     />
  //   </div>
  // );

  return (
    <button
      onClick={onClick}
      className='flex items-center justify-center h-9 w-9 p-2 rounded-full text-gray-500 bg-white hover:bg-gray-50 transition-all'
    >
      <Icon as={IoIosMenu} boxSize={6} />
    </button>
  )
};

export default HamburgerMenu;