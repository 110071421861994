import { Tag } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { FaCircle } from 'react-icons/fa6';

const StatusLabel = ({
  status = 'Unknown',
  colorScheme = 'gray',
  StatusIcon = FaCircle,
}) => {
  return (
    <Tag size='sm' colorScheme={colorScheme} className='flex gap-1.5 items-center py-1 w-fit'>
      <Icon as={StatusIcon} boxSize={2} className='opacity-75' />
      <span className='capitalize opacity-85' >{status}</span>
    </Tag>
  );
};

export default StatusLabel;
