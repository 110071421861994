import React, { useState } from "react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Toggle = ({
  selected,
  editPath,
  viewPath,
  editText = "Edit Details",
}) => {
  const navigate = useNavigate();
  const [_selected, setSelected] = useState(selected);
  return (
    <ButtonGroup>
      <Button
        colorScheme="gray"
        variant={_selected === "edit" ? "solid" : "outline"}
        onClick={() => {
          setSelected("edit");
          navigate(editPath);
        }}
        className="text-md font-medium py-1 px-4 h-8"
      >
        {editText}
      </Button>
      <Button
        colorScheme="gray"
        variant={_selected === "view" ? "solid" : "outline"}
        onClick={() => {
          setSelected("view");
          navigate(viewPath);
        }}
        className="text-md font-medium py-1 px-4 h-8"
      >
        View Details
      </Button>
    </ButtonGroup>
  );
};

export default Toggle;
