import { Link } from "common/utils/user-tracker/react-router-dom";

const DashboardCard = ({ Icon, path = "", title = "" }) => {
  return (
    <Link linkId={title} to={path}>
      <div className="grid grid-cols-[20px_1fr] items-center border border-gray-200 rounded-lg p-5 gap-6 mobile:gap-3 hover:bg-gray-50">
        <Icon className="text-xl text-gray-500" />
        <span className="text-lg font-medium">{title}</span>
      </div>
    </Link>
  );
};

export default DashboardCard;
