import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFindPropertyQuery } from 'redux/api/ciosAdminApi/ciosAdminApi';
import PropertiesTable from 'components/properties/properties-table';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import TablePaginateWrapper from 'common/layouts/table-paginate-wrapper';
import { isEmpty } from 'utils/isEmpty';
import SearchBar from 'common/components/search-bar';
import EmptyPage from 'common/components/empty-page';
import { propertyStatusEnum } from 'common/utils/enums';
import Filter from 'common/components/filter';

const PropertyFind = () => {
  const navigate = useNavigate();
  // DATA INITIALIZATION
  const pageSize = 15;

  const [currentPage, setCurrentPage] = useState(1);
  const [sortVal, setSort] = useState('-createdAt');
  const [textFilter, setTextFilter] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [statuses, setCurrentStatuses] = useState([]);

  const {
    data: propertiesQuery,
    isError: isGetError,
    error: getError,
    isLoading: findPropertiesLoading,
  } = useFindPropertyQuery({
    limit: pageSize,
    page: currentPage,
    sort: sortVal,
    textFilter,
    status: statuses,
  });

  const { data: propertiesData, meta: propertiesMeta } = propertiesQuery || {
    data: [],
    meta: { count: 0 },
  };

  // Update URL when filters change
  useEffect(() => {
    const params = {};
    if (statuses.length > 0) {
      params.statuses = statuses.join(',');
    }

    if (textFilter) {
      params.textFilter = textFilter;
    }
    setSearchParams(params);
  }, [statuses, textFilter, setSearchParams]);

  // Pull query variables from the URL to set the initial state
  useEffect(() => {
    const statusQuery = searchParams.get('statuses');
    if (statusQuery) {
      setCurrentStatuses(statusQuery.split(','));
    } else {
      setCurrentStatuses([
        propertyStatusEnum.OPTIN,
        propertyStatusEnum.OPTOUT,
        propertyStatusEnum.REQUIRED,
      ]);
    }
  }, [searchParams]);

  return (
    <DashboardWrapperLayout
      title='Properties'
      buttons={
        !isGetError ? (
          <SearchBar
            placeholderText='Search by name'
            value={textFilter}
            onSearch={(e) => setTextFilter(e.target.value)}
            buttonText={'Add Property'}
            onClick={() => navigate(`/dashboard/properties/new`)}
          />
        ) : null
      }
    >
      <Filter
        statuses={statuses}
        setCurrentStatuses={setCurrentStatuses}
        statusOptions={propertyStatusEnum}
      />

      {isGetError ? (
        <EmptyPage
          isError={isGetError}
          title='Error Loading Properties'
          subtitle={getError?.data?.msg}
          buttonText='Reload Page'
          onClick={() => window.location.reload()}
        />
      ) : findPropertiesLoading ? (
        <EmptyPage
          title='Loading Properties'
          subtitle='Please wait while we load your properties'
          isLoading={findPropertiesLoading}
        />
      ) : isEmpty(propertiesData) ? (
        <EmptyPage
          title='No Properties Found'
          subtitle='No properties found. Try adding a new property.'
          buttonText={'Add Property'}
          buttonAction={() => navigate(`/dashboard/properties/new`)}
        />
      ) : (
        <TablePaginateWrapper
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalCount={propertiesMeta.count}
        >
          <PropertiesTable
            properties={propertiesData}
            setSort={setSort}
            sortVal={sortVal}
          />
        </TablePaginateWrapper>
      )}
    </DashboardWrapperLayout>
  );
};

export default PropertyFind;
