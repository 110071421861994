import React from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { usePagination } from 'common/hooks/usePagination';
import { DOTS } from 'common/utils/enums';
import Button from 'common/components/button';
import { iconPositionEnum } from 'common/utils/enums';

const Pagination = ({
  setCurrentPage,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize = 10,
}) => {
  // HOOKS
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // HANDLERS
  const onNext = () => {
    setCurrentPage(currentPage + 1);
  };
  const onPrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  // DATA INITIALIZATION
  const lastPage = paginationRange[paginationRange.length - 1];

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  return (
    <div className='w-full flex items-center justify-between gap-2 py-4 border-t border-gray-200'>
      {/* back button */}
      <Button
        disabled={currentPage <= 1}
        onClick={onPrevious}
        wFull={false}
        theme='outline'
        iconPosition={iconPositionEnum.LEFT}
        Icon={BsArrowLeft}
        title='Previous'
        hideTitle
      />

      {/* pagination items */}
      <ul className='flex items-center gap-3 mobile:hidden'>
        {paginationRange?.map((pageNumber, index) => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === DOTS) {
            return (
              <li
                key={index}
                className='inline-flex items-center justify-center w-10 h-10 text-sm font-medium rounded-full text-gray-500'
              >
                &#8230;
              </li>
            );
          }

          // Render the pagination items
          return (
            <li
              onClick={() => setCurrentPage(pageNumber)}
              key={index}
              className={`inline-flex items-center justify-center w-10 h-10 text-sm font-medium rounded-full cursor-pointer ${
                currentPage === pageNumber
                  ? 'border border-black bg-black text-white'
                  : 'text-gray-500'
              }`}
            >
              {pageNumber}
            </li>
          );
        })}
      </ul>
  
      {/* next button */}
      <Button
        disabled={currentPage >= lastPage}
        onClick={onNext}
        wFull={false}
        theme='outline'
        iconPosition={iconPositionEnum.RIGHT}
        Icon={BsArrowRight}
        title='Next'
        hideTitle
      />
    </div>
  );
};

export default Pagination;
