import { extractInitials } from "common/utils/extractInitials";

const ProfileLink = ({ user, onClick }) => {
  return (
    <div className="flex flex-col lg:mt-auto lg:pt-2 lg:border-t lg:border-gray-200 lg:w-full">
      {/* profile details */}
      <div
        className="cursor-pointer hover:bg-gray-50 rounded-md text-base font-medium items-center gap-3 lg:p-2 lg:grid lg:grid-cols-[32px_1fr]"
        onClick={onClick}
      >
        {/* profile image */}
        <div className="flex bg-black p-2 h-[32px] w-[32px] rounded-full items-center justify-center text-white font-medium text-sm uppercase">
          {extractInitials(
            user?.name || `${user?.firstName} ${user?.lastName}`
          )}
        </div>

        {/* name and email address */}
        <div className="flex-col w-full overflow-hidden text-sm items-start hidden lg:flex">
          <span className="text-left text-black truncate w-full">
            {user?.name || `${user?.firstName} ${user?.lastName}`}
          </span>
          <span className="text-left text-gray-500 truncate w-full">
            {user?.email}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProfileLink;
