import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ClaimsTable from 'components/claims/claims-table';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import TablePaginateWrapper from 'common/layouts/table-paginate-wrapper';
import { isEmpty } from 'utils/isEmpty';
import { useFindClaimsQuery } from 'redux/api/ciosAdminApi/ciosAdminApi';
import { claimStatusEnum } from 'common/utils/enums';
import SearchBar from 'common/components/search-bar';
import Filter from 'common/components/filter';
import EmptyPage from 'common/components/empty-page';

const ClaimFind = () => {
  // DATA INITIALIZATION
  const pageSize = 15;

  // STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [sortVal, setSort] = useState('-statusChanges.updatedAt');
  const [textFilter, setTextFilter] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [statuses, setCurrentStatuses] = useState([]);

  // Update URL when filters change
  useEffect(() => {
    const params = {};
    if (statuses.length > 0) {
      params.statuses = statuses.join(',');
    }

    if (textFilter) {
      params.textFilter = textFilter;
    }
    setSearchParams(params);
  }, [statuses, textFilter, setSearchParams]);

  // Pull query variables from the URL to set the initial state
  useEffect(() => {
    const statusQuery = searchParams.get('statuses');
    if (statusQuery) {
      setCurrentStatuses(statusQuery.split(','));
    } else {
      setCurrentStatuses([
        claimStatusEnum.SUBMITTED,
        claimStatusEnum.IN_REVIEW,
        claimStatusEnum.RESOLVED,
        claimStatusEnum.CONFIRMED,
      ]);
    }
  }, [searchParams]);

  const {
    data: claimsQuery,
    isError: isGetError,
    error: getError,
    isLoading: findClaimsLoading,
  } = useFindClaimsQuery({
    limit: pageSize,
    page: currentPage,
    sort: sortVal,
    textFilter,
    status: statuses,
  });

  const { data: claimsData, meta: claimsMeta } = claimsQuery || {
    data: [],
    meta: { count: 0 },
  };

  return (
    <DashboardWrapperLayout
      title='Claims'
      buttons={
        !isGetError ? (
          <SearchBar
            placeholderText='Search by ID'
            value={textFilter}
            onSearch={(e) => setTextFilter(e.target.value)}
          />
        ) : null
      }
    >
      {/* Filter Area */}
      <Filter
        statusOptions={claimStatusEnum}
        statuses={statuses}
        setCurrentStatuses={setCurrentStatuses}
      />

      {/* table */}
      {isGetError ? (
        <EmptyPage
          isError={isGetError}
          title='Error Loading Claims'
          subtitle={getError?.data?.msg}
          buttonText='Reload Page'
          onClick={() => window.location.reload()}
        />
      ) : findClaimsLoading ? (
        <EmptyPage
          isLoading={findClaimsLoading}
          title='Loading Claims'
          subtitle='Please wait while we load your claims.'
        />
      ) : isEmpty(claimsData) ? (
        <EmptyPage
          title='No Claims Found'
          subtitle='There are no claims available.'
        />
      ) : (
        <TablePaginateWrapper
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalCount={claimsMeta.count}
        >
          <ClaimsTable
            claims={claimsData}
            setSort={setSort}
            sortVal={sortVal}
          />
        </TablePaginateWrapper>
      )}
    </DashboardWrapperLayout>
  );
};

export default ClaimFind;
