import React from 'react';
import { propertyStatusEnum } from 'common/utils/enums';
import StatusLabelView from 'common/components/status-label';

const StatusLabel = ({ status = 'Inactive' }) => {
  // DATA INITIALIZATION
  let colorScheme = '';

  switch (status) {
    case propertyStatusEnum.INACTIVE:
      colorScheme = 'gray';
      break;
    case propertyStatusEnum.OPTIN:
      colorScheme = 'yellow';
      break;
    case propertyStatusEnum.OPTOUT:
      colorScheme = 'blue';
      break;
    case propertyStatusEnum.REQUIRED:
      colorScheme = 'green';
      break;
    default:
      colorScheme = 'gray';
  }

  return <StatusLabelView status={status} colorScheme={colorScheme} />;
};

export { StatusLabel };
