// ENUM VALUES
export const currency = {
  USD: "$",
};

export const propertyStatusEnum = {
  INACTIVE: "Inactive",
  OPTOUT: "Opt-Out",
  OPTIN: "Opt-In",
  REQUIRED: "Required",
};

export const propertyKindEnum = {
  MULTIFAMILY: "Multifamily",
  STUDENT: "Student Housing",
  OTHER: "Other",
};

export const planPaymentMode = {
  ONE_TIME: "onetime",
  SUBSCRIPTION: "subscription",
};

export const coverageTypes = {
  CANCEL_ANYTIME: "cancelAnytime",
  LEAVE_ANYTIME: "leaveAnytime",
};

export const planStatusEnum = {
  PENDING: "pending",
  ACTIVE: "active",
  COMPLETE: "complete",
  REJECTED: "rejected",
  CANCELLED: "cancelled",
  CREATED: "created",
  REVIEW: "review",
  PAID: "paid",
};

export const statusEnum = {
  // PLANS
  pending: "pending",
  active: "active",
  complete: "complete",
  rejected: "rejected",
  cancelled: "cancelled",
  created: "created",
  review: "review",
  paid: "paid",

  // CLAIMS
  Draft: "Draft",
  submitted: "submitted",
  Completed: "Completed",
  inReview: "in Review",
  Expired: "Expired",
  Denied: "Denied",
  Approved: "Approved",
  Confirmed: "Confirmed",
};
export const claimTypeEnum = {
  NEW_JOB: "newJob",
  PURCHASED_HOME: "purchasedHome",
  OTHER_CLAIM: "other",
};
export const claimStatusEnum = {
  DRAFT: "Draft",
  SUBMITTED: "submitted",
  COMPLETED: "Completed",
  IN_REVIEW: "in Review",
  EXPIRED: "Expired",
  DENIED: "Denied",
  APPROVED: "Approved",
  CONFIRMED: "Confirmed",
};

export const claimPaymentPreferenceEnum = {
  DIRECT: "direct",
  REIMBURSEMENT: "reimbursement",
};

export const ownerStatusEnum = {
  ACTIVE: "Active",
  DELETED: "Deleted",
};

export const ownerKindEnum = {
  OWNER: "Owner",
  MANAGER: "Manager",
};

export const stripeKYCEnum = {
  pending: "pending",
  failed: "error",
  verified: "verified",
};
export const planPaymentType = {
  ONETIME: 1,
  SUBSCRIPTION: 2,
};
export const DOTS = "...";
export const PENDING = "pending";
export const PENDING_PAYMENT = "Pending Payment";
export const ACTIVE = "Active";

export const MAX_DOCUMENT_SIZE = 20971520; // 20MB
export const MAX_PROFILE_IMAGE_SIZE = 5242880;

// INITIAL VALUES
export const uploadDocumentSchema = {
  path: "",
  name: "",
  size: 0,
  type: "",
  webkitRelativePath: "",
};
export const localeTimeOptions = {
  day: "numeric",
  month: "short",
  year: "numeric",
};

export const shortTime = new Intl.DateTimeFormat("en", {
  timeStyle: "short",
});

export const settingsTab = {
  ACCOUNT: 1,
  PAYMENT: 2,
  VERIFICATION: 3,
};

export const iconPositionEnum = {
  LEFT: 1,
  RIGHT: 2,
};

export const POLLING_INTERVAL = 3000; //milliseconds

export const userTrackingEnum = {
  CONSOLE: "console",
  SEGMENT: "segment",
};

export const tenantStatusEnum = {
  NEW: "New",
  INVITED: "Invited",
  ACCEPTED: "Accepted",
  DELETED: "Deleted",
  VERIFIED: "Verified",
  NOT_VERIFIED: "Not Verified",
};

export const documentStatusEnum = {
  NONE: "none",
  UPLOADED: "uploaded",
  REJECTED: "rejected",
  VERIFIED: "verified",
};

export const planPaymentEnum = {
  ONETIME: "onetime",
  MONTHLY: "monthly",
};

export const verificationStatusEnum = {
  VERIFIED: true,
  NOT_VERIFIED: false,
};

export const paymentMethodEnum = {
  WIRE: "wire",
  ACH: "ach",
  ETRANSFER: "etransfer",
  CHECK: "check",
};

export const claimConfirmationTypeEnum = {
  VACATED: "vacated",
  CURRENT: "current",
  RELISTED: "relisted",
};

export const rentalTypeEnum = {
  MULTIFAMILY: "Multifamily",
  STUDENT_HOUSING: "Student Housing",
  OTHER: "Other",
};
