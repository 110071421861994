import ModalBgLayout from 'common/layouts/modal-bg';
import Button from 'common/components/button';

const ConfirmActionModal = ({ onConfirmClick, onCancel, action, description }) => {
  return (
    <ModalBgLayout>
      <div className='flex flex-col gap-2'>
        {/* icon and texts*/}
        <h3 className='font-medium'>
          Are you sure{action && ` you want to ${action}`}?
        </h3>
        <p className='text-base text-gray-500'>
          {description || 'This action cannot be undone.'} 
        </p>

        {/* buttons */}
        <div className='flex items-center w-full gap-3 mt-6 flex-col sm:flex-row'>
          <Button onClick={onCancel} theme='secondary' title='No, go back' />
          <Button onClick={onConfirmClick} title={action ? `Yes, ${action}` : 'Yes'} />
        </div>
      </div>
    </ModalBgLayout>
  );
};

export default ConfirmActionModal;
