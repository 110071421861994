import Button from "common/components/button";
import { FaPlus, FaXmark } from "react-icons/fa6";
import React, { useState } from "react";

const FloatingButtons = ({
  children,
  OpenIcon = FaPlus,
  CloseIcon = FaXmark,
  button,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isEmpty = React.Children.count(children) === 0;

  return (
    <div className="fixed bottom-6 right-6 flex flex-col gap-4 z-30 items-end">
      {isOpen ? (
        <div className="flex flex-col gap-3 relative bottom-6 items-end">
          {children}
        </div>
      ) : null}

      <div className="flex gap-3">
        {button}

        {isEmpty ? null : (
          <Button
            onClick={() => setIsOpen(!isOpen)}
            Icon={isOpen ? CloseIcon : OpenIcon}
            theme={button ? "secondary" : "primary"}
            title={button ? 'More Actions' : 'Actions'}
            className="shadow-md"
            wFull={false}
          />
        )}
      </div>
    </div>
  );
};

export default FloatingButtons;
