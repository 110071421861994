import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdClose } from 'react-icons/md';
import { MAX_DOCUMENT_SIZE } from 'common/utils/enums';
import { isEmpty } from 'common/utils/isEmpty';

const FileUploadInput = ({
  disableUpload,
  isUploadSuccess,
  handlePDFUpload,
  isUploadLoading,
  isUploadError,
  handlePDFClear,
}) => {
  // STATES
  const [pickedPDF, setPickedPDF] = useState({
    path: '',
    name: '',
    size: 0,
    type: '',
    webkitRelativePath: '',
  });
  const [error, setError] = useState('');

  // HANDLERS
  const handleClearPickerImage = () => {
    handlePDFClear && handlePDFClear();
    setPickedPDF({
      path: '',
      name: '',
      size: 0,
      type: '',
      webkitRelativePath: '',
    });
  };

  // DATA INITIALIZATION
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf*': ['.pdf'],
      'image/*': ['.png', '.jpeg', '.jpg'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
    },
    maxFiles: 1,
    multiple: false,
    maxSize: MAX_DOCUMENT_SIZE,
    onDrop: (acceptedFiles) => {
      setError('');
      setPickedPDF(acceptedFiles[0]);
    },
    onDropRejected() {
      setError('This file was rejected');
    },
  });

  // SIDE EFFECTS
  useEffect(() => {
    if (!isEmpty(pickedPDF?.path)) {
      handlePDFUpload && handlePDFUpload(pickedPDF);
    }
  }, [pickedPDF]);

  return (
    <div className='relative'>
      <div
        {...(isEmpty(pickedPDF?.name) ? getRootProps() : undefined)}
        className={`flex items-center w-full border rounded-md bg-white h-9 px-3 border-gray-200 gap-x-2`}
      >
        {/* remove button */}
        {isEmpty(pickedPDF?.name) || isUploadLoading ? null : (
          <button
            onClick={handleClearPickerImage}
            type='button'
            className='rounded border border-gray-200 py-1 px-2 text-sm font-medium cursor-pointer hover:bg-gray-100'
          >
            Clear
          </button>
        )}

        {/* view uploaded button */}
        {pickedPDF?.name && !isUploadLoading ? (
          <button
            onClick={() => {
              const url = URL.createObjectURL(pickedPDF);
              window.open(url, '_blank');
            }}
            type='button'
            className='rounded border border-gray-200 py-1 px-2 text-sm font-medium cursor-pointer hover:bg-gray-100'
          >
            Preview
          </button>
        ) : null}

        {/* choose file button */}
        {isUploadSuccess && !isEmpty(pickedPDF?.name) ? (
          <span className='text-base font-normal truncate ...'>
            {pickedPDF?.name || 'Uploaded'}
          </span>
        ) : isEmpty(pickedPDF?.name) ? (
          <button
            type='button'
            className='rounded border border-gray-200 py-1 px-2 text-sm font-medium cursor-pointer hover:bg-gray-100'
          >
            <input {...getInputProps()} />
            Upload File
          </button>
        ) : (
          // upload file button
          <button
            type='button'
            onClick={() => {
              handlePDFUpload(pickedPDF);
            }}
            disabled={
              (isUploadSuccess && !isEmpty(pickedPDF?.name)) ||
              isUploadLoading ||
              disableUpload
            }
            className={`rounded border border-gray-200 py-1 px-2 text-sm font-medium cursor-pointer ${
              isUploadError
                ? 'bg-red-100 hover:bg-red-800'
                : 'bg-white hover:bg-gray-100'
            }`}
          >
            {isUploadLoading
              ? 'Uploading...'
              : isUploadError
                ? 'Retry'
                : isUploadSuccess && !isEmpty(pickedPDF?.name)
                  ? 'Uploaded'
                  : 'Upload'}
          </button>
        )}

        {/* file description */}
        <div className='ml-1'>
          {isUploadSuccess ? null : !isEmpty(error) ? (
            <span className='font-normal text-red-500'>{error}</span>
          ) : !isEmpty(pickedPDF?.name) ? (
            <span className='font-normal text-base truncate ...'>
              {pickedPDF?.name}
            </span>
          ) : (
            <span className='font-normal text-base'>No File Uploaded</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUploadInput;
