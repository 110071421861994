import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useFindOwnersQuery } from 'redux/api/ciosAdminApi/ciosAdminApi';
import OwnersTable from 'components/owners/owners-table';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import TablePaginateWrapper from 'common/layouts/table-paginate-wrapper';
import { isEmpty } from 'utils/isEmpty';
import SearchBar from 'common/components/search-bar';
import EmptyPage from 'common/components/empty-page';
import { ownerStatusEnum } from 'common/utils/enums';
import Filter from 'common/components/filter';

const OwnerFind = () => {
  const navigate = useNavigate();
  // DATA INITIALIZATION
  const pageSize = 15;

  const [currentPage, setCurrentPage] = useState(1);
  const [sortVal, setSort] = useState('-createdAt');
  const [textFilter, setTextFilter] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [statuses, setCurrentStatuses] = useState([]);

  const {
    data: ownersQuery,
    isError: isGetError,
    error: getError,
    isLoading: findOwnersLoading,
  } = useFindOwnersQuery({
    limit: pageSize,
    page: currentPage,
    sort: sortVal,
    textFilter,
    status: statuses,
  });

  const { data: ownersData, meta: ownersMeta } = ownersQuery || {
    data: [],
    meta: { count: 0 },
  };

  // Update URL when filters change
  useEffect(() => {
    const params = {};
    if (statuses.length > 0) {
      params.statuses = statuses.join(',');
    }

    if (textFilter) {
      params.textFilter = textFilter;
    }
    setSearchParams(params);
  }, [statuses, textFilter, setSearchParams]);

  // Pull query variables from the URL to set the initial state
  useEffect(() => {
    const statusQuery = searchParams.get('statuses');
    if (statusQuery) {
      setCurrentStatuses(statusQuery.split(','));
    } else {
      setCurrentStatuses([ownerStatusEnum.ACTIVE]);
    }
  }, [searchParams]);

  return (
    <DashboardWrapperLayout
      title='Owners'
      buttons={
        !isGetError ? (
          <SearchBar
            placeholderText='Search by name or ID'
            value={textFilter}
            onSearch={(e) => setTextFilter(e.target.value)}
            buttonText={'Add Owner'}
            onClick={() => navigate(`/dashboard/owners/new`)}
          />
        ) : null
      }
    >
      <Filter
        statuses={statuses}
        setCurrentStatuses={setCurrentStatuses}
        statusOptions={ownerStatusEnum}
      />

      {isGetError ? (
        <EmptyPage
          isError={isGetError}
          title='Error Loading Owners'
          subtitle={getError?.data?.msg}
          buttonText='Reload Page'
          onClick={() => window.location.reload()}
        />
      ) : findOwnersLoading ? (
        <EmptyPage
          title='Loading Owners'
          subtitle='Please wait while we load your owners'
          isLoading={findOwnersLoading}
        />
      ) : isEmpty(ownersData) ? (
        <EmptyPage
          title='No Owners Found'
          subtitle='No owners found. Try adding a new owner.'
          buttonText={'Add Owner'}
          onClick={() => navigate(`/dashboard/owners/new`)}
        />
      ) : (
        <TablePaginateWrapper
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalCount={ownersMeta.count}
        >
          <OwnersTable
            owners={ownersData}
            setSort={setSort}
            sortVal={sortVal}
          />
        </TablePaginateWrapper>
      )}
    </DashboardWrapperLayout>
  );
};

export default OwnerFind;
