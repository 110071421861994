import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PlansTable from 'components/plans/plans-table';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import TablePaginateWrapper from 'common/layouts/table-paginate-wrapper';
import { isEmpty } from 'common/utils/isEmpty';
import { useFindPlansQuery } from 'redux/api/ciosAdminApi/ciosAdminApi';
import { planStatusEnum } from 'common/utils/enums';
import { useNavigate } from 'react-router-dom';
import Filter from 'common/components/filter';
import SearchBar from 'common/components/search-bar';
import EmptyPage from 'common/components/empty-page';

const PlanFind = () => {
  const navigate = useNavigate();
  const pageSize = 15;

  const [currentPage, setCurrentPage] = useState(1);
  const [sortVal, setSort] = useState('-createdAt');
  const [textFilter, setTextFilter] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [statuses, setCurrentStatuses] = useState([]);

  const {
    data: plansQuery,
    isError: isGetError,
    error: getError,
    isLoading: findPlansLoading,
  } = useFindPlansQuery({
    limit: pageSize,
    page: currentPage,
    sort: sortVal,
    textFilter,
    status: statuses,
  });

  const { data: plansData, meta: plansMeta } = plansQuery || {
    data: [],
    meta: { count: 0 },
  };

  // HOOKS

  // Update URL when filters change
  useEffect(() => {
    const params = {};
    if (statuses.length > 0) {
      params.statuses = statuses.join(',');
    }

    if (textFilter) {
      params.textFilter = textFilter;
    }
    setSearchParams(params);
  }, [statuses, textFilter, setSearchParams]);

  // Pull query variables from the URL to set the initial state
  useEffect(() => {
    const statusQuery = searchParams.get('statuses');
    if (statusQuery) {
      setCurrentStatuses(statusQuery.split(','));
    } else {
      setCurrentStatuses([
        planStatusEnum.CREATED,
        planStatusEnum.PENDING,
        planStatusEnum.REVIEW,
        planStatusEnum.ACTIVE,
      ]);
    }
  }, [searchParams]);

  return (
    <DashboardWrapperLayout
      title='Plans'
      buttons={
        !isGetError ? (
          <SearchBar
            placeholderText='Search by ID or Name'
            value={textFilter}
            onSearch={(e) => setTextFilter(e.target.value)}
            buttonText='Add Plan'
            onClick={() => {
              navigate('/dashboard/plans/new');
            }}
          />
        ) : null
      }
    >
      {/* Filter Area */}
      <Filter
        statusOptions={planStatusEnum}
        statuses={statuses}
        setCurrentStatuses={setCurrentStatuses}
      />

      {/* table */}
      {isGetError ? (
        <EmptyPage
          isError={isGetError}
          title='Error Loading Plans'
          subtitle={getError?.data?.msg}
          buttonText='Reload Page'
          onClick={() => window.location.reload()}
        />
      ) : findPlansLoading ? (
        <EmptyPage
          isLoading={findPlansLoading}
          title='Loading Plans'
          subtitle='Please wait while we load your plans'
        />
      ) : isEmpty(plansData) ? (
        <EmptyPage
          title='No Plans Found'
          subtitle='There are no plans available'
          buttonText={'Add Plan'}
          onClick={() => {
            navigate('/dashboard/plans/new');
          }}
        />
      ) : (
        <TablePaginateWrapper
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalCount={plansMeta.count}
        >
          <PlansTable setSort={setSort} sortVal={sortVal} plans={plansData} />
        </TablePaginateWrapper>
      )}
    </DashboardWrapperLayout>
  );
};

export default PlanFind;
