import Button from 'common/components/button';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <DashboardWrapperLayout>
      <div className="flex flex-col items-center justify-center h-full w-full m-4 border border-gray-200 rounded-lg">
        <div className='max-w-md flex flex-col gap-2 items-center text-center'>
          <h1 className="text-4xl font-display tracking-tight">Page Not Found</h1>
          <p className="text-base text-gray-500">
            The page you are looking for does not exist. Please check the URL or
            click the button below to go back to your dashboard.
          </p>
          <Button
            wFull={false}
            title='Go to Dashboard'
            onClick={() => navigate('/dashboard')}
            className='mt-6'
          />
        </div>
      </div>
    </DashboardWrapperLayout>
  );
};

export default PageNotFound;
