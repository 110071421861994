import React from 'react';
import { tenantStatusEnum } from 'common/utils/enums';
import StatusLabelView from 'common/components/status-label';

const StatusLabel = ({ status = 'In Review' }) => {
  // DATA INITIALIZATION
  let colorScheme = '';

  switch (status) {
    case tenantStatusEnum.NEW:
      colorScheme = 'gray';
      break;
    case tenantStatusEnum.INVITED:
      colorScheme = 'yellow';
      break;
    case tenantStatusEnum.ACCEPTED:
      colorScheme = 'green';
      break;
    case tenantStatusEnum.DELETED:
      colorScheme = 'red';
      break;
    default:
      colorScheme = 'gray';
  }

  return <StatusLabelView colorScheme={colorScheme} status={status} />;
};

export { StatusLabel };
