import { PhoneInput } from 'react-international-phone';

const PhoneNumberInput = ({
  className,
  onChange,
  onBlur,
  value,
  name='phone',
  defaultCountry='US',
  disabled=false,
}) => {
  return (
    <PhoneInput
      countrySelectorStyleProps={{
        buttonClassName: '!h-full !border-none',
      }}
      className={`flex items-center justify-between w-full border border-gray-200 rounded-md bg-white h-9 px-3 gap-x-3 ${disabled && 'bg-gray-100 opacity-70'} ${className}`}
      defaultCountry={defaultCountry}
      inputClassName='w-full !h-full !border-none !bg-transparent'
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
  )
}

export { PhoneNumberInput }
