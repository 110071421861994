import { useNavigateSearch } from 'hooks/useNavigateSearch';
import StatusLabel from 'components/plans/status-label';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import SortButton from 'common/components/sort-button';
import { CopyIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react';
import {
  ViewIcon,
  AddIcon,
} from '@chakra-ui/icons';
import {
  Table,
  HeaderRow,
  HeaderCell,
  TableBody,
  BodyRow,
  TableCell,
} from 'common/components/data-table';
import { FaPeopleLine, FaStripeS } from 'react-icons/fa6';
import FormatAsCurrency from 'common/utils/formatAsCurrency';
import copyToClipboard from 'common/utils/copyToClipboard';
import { toast } from 'react-toastify';

// isRounded controls the top border radius rounding. Use when there is a header above the normal header row
// showMember controls whether the member name+email is shown. Use when there is no reason to show this info, like on the member plans page.

const PlansTable = ({
  plans,
  isRounded = true,
  tenantName,
  tenantEmail,
  sortVal,
  setSort,
  showMember = true,
}) => {
  // DATA INITIALIZATION
  const navigate = useNavigateSearch();

  const gridLayout = showMember
  ? 'grid-cols-5 mobile:grid-cols-2'
  : 'grid-cols-4 mobile:grid-cols-2';

  return (
    <Table>
      {/* head */}
      <HeaderRow
        isRounded={isRounded}
        gridLayout={gridLayout}
      >
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='createdAt'
            label='Plan ID'
          />
        </HeaderCell>
        {showMember && <HeaderCell className='mobile:hidden'>Member</HeaderCell>}
        <HeaderCell className='mobile:hidden'>Address</HeaderCell>
        <HeaderCell className='mobile:hidden'>
          Lease
        </HeaderCell>
        <HeaderCell>Status</HeaderCell>
      </HeaderRow>

      {/* body */}
      <TableBody>
        {plans.map((plan, index) => (
          <BodyRow
            gridLayout={gridLayout}
            key={index}
            onClick={() => {
              navigate(`/dashboard/plans/${plan.id}`);
            }}
          >
            {/* Plan ID and Create Date */}
            <TableCell
              top={plan?.id}
              bottom={`Created ${formatDateToHumanDate(plan?.createdAt)}`}
            />

            {/* profile */}
            {showMember && (
              <TableCell
                top={
                  tenantName || plan?.user?.name
                    ? plan?.user?.name
                    : `${plan?.user?.firstName} ${plan?.user?.lastName}`
                }
                bottom={tenantEmail || plan?.user?.email}
                bottomLink={`mailto:${tenantEmail || plan?.user?.email}`}
                onClick={(e) => {
                  e.stopPropagation();
                  return false;
                }}
                className='mobile:hidden'
              />
            )}

            {/* Address */}
            {/* Placeholder when address is undefined (shouldn't happen) */}
            {plan?.lease?.address?.streetAddress1 ? (
              <TableCell
                top={`${plan?.lease?.address?.streetAddress1}${
                  plan?.lease?.address?.streetAddress2 ? `, ${plan?.lease?.address?.streetAddress2}` : ''}`}
                bottom={`${plan?.lease?.address?.city}, ${plan?.lease?.address?.state} ${plan?.lease?.address?.postalCode}`}
                className='mobile:hidden'
              />
            ) : (
              <TableCell top='-' bottom='-' className='mobile:hidden' />
            )}

            {/* Lease */}
            {plan?.lease ? (
              <TableCell
                top={`${formatDateToHumanDate(plan?.lease?.startDate)} - ${formatDateToHumanDate(plan?.lease?.endDate)}`}
                bottom={`${FormatAsCurrency(plan?.lease?.monthlyRent, 'USD')} / month`}
                className='mobile:hidden'
              />
            ): (
              <TableCell top='-' bottom='-' className='mobile:hidden' />
            )}

            {/* Status and Edit button */}
            <TableCell
              className='justify-between'
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            >
              {plan?.status == 'pending' ? (
                <StatusLabel
                  size='sm'
                  status={plan?.status}
                />
              ) : (
                <StatusLabel size='sm' status={plan?.status} />
              )}
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<HamburgerIcon />}
                  variant='ghost'
                />
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/plans/${plan?.id}`);
                    }}
                    icon={<ViewIcon />}
                  >
                    View Plan Details
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/plans/${plan?.id}/edit`);
                    }}
                    icon={<AddIcon />}
                  >
                    Edit Plan Details
                  </MenuItem>
                  {plan?.stripePaymentID || plan?.stripeSubscriptionID ? (
                    <MenuItem
                      onClick={(e) => {
                        if (plan?.stripePaymentID) {
                          let url = `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}payments/${plan?.stripePaymentID}`;
                          window.open(url, '_blank');
                        } else if (plan?.stripeSubscriptionID) {
                          let url = `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}subscriptions/${plan?.stripeSubscriptionID}`;
                          window.open(url, '_blank');
                        } else {
                          let url = `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}`;
                          window.open(url, '_blank');
                        }
                      }}
                      icon={<FaStripeS />}
                    >
                      Open in Stripe
                    </MenuItem>
                  ): null}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/members/${plan?.user?.id}`);
                    }}
                    icon={<FaPeopleLine />}
                  >
                    View Member Details
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(tenantEmail || plan?.user?.email);
                      toast.success(`Copied member email to clipboard`);
                    }}
                    icon={<CopyIcon />}
                  >
                    Copy Member Email
                  </MenuItem>
                </MenuList>
              </Menu>
            </TableCell>
          </BodyRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PlansTable;
