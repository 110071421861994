import { useEffect, useState } from 'react';
import {
  CANCEL_ANYTIME_RENT_PERCENTAGE,
  LEAVE_ANYTIME_RENT_PERCENTAGE,
  LEAVE_ANYTIME_ONETIME_DISCOUNT,
} from 'common/utils/consts';
import Toggle from 'common/components/toggle';
import AutoCompleteAddress from 'common/components/autoaddress';
import * as Yup from 'yup';
import moment from 'moment';
import { isEmpty } from 'utils/isEmpty';
import FormError from 'common/components/form-error';
import { toast } from 'react-toastify';
import { Form, Formik, useFormikContext } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import Input from 'common/components/input';
import SelectInput from 'common/components/select-input';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { coverageTypes, planPaymentMode } from 'common/utils/enums';
import { toDatePickerFormat } from 'utils/date';
import { uploadFileToS3 } from 'utils/uploadS3';
import {
  useGetPlanQuery,
  useUpdatePlanMutation,
  useUploadRequestUrlMutation,
  useCreatePlanMutation,
  useFindPropertyQuery,
  useFindTenantsQuery,
  useGetPropertyQuery,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { planStatusEnum } from 'common/utils/enums';
import FileUploadInput from 'common/components/file-upload-input';
import {
  documentStatusOptions,
  planCurrencyOptions,
  residencyStatusOptions,
} from 'common/utils/selectOptions';
import Button from 'common/components/button';
import { InfoCard, InfoGrid, InfoField } from 'common/components/info-card';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import {
  allPlanStatusOptions,
  booleanOptions,
} from 'common/utils/selectOptions';
import FloatingButtons from 'common/components/floating-action-button';
import EmptyPage from 'common/components/empty-page';
import { Tag } from '@chakra-ui/react';

// Can get the number of months here
function getTerm(startDate, endDate) {
  if (!startDate || !endDate) {
    return 12;
  }

  let start = moment(startDate);
  let end = moment(endDate);

  return end.diff(start, 'months');
}

// These are the default values if you want to use them
function getCancelAnytimeConfig({ plan }) {
  const lease = plan?.lease;
  const { startDate, endDate, monthlyRent } = lease;
  const term = getTerm(startDate, endDate);
  const total = monthlyRent * term;
  const oneTimeAmount = total * CANCEL_ANYTIME_RENT_PERCENTAGE;
  return {
    term,
    startDate,
    endDate,
    payment: {
      amount: oneTimeAmount,
      rentPercentage: CANCEL_ANYTIME_RENT_PERCENTAGE,
    },
  };
}

function getLeaveAnytimeConfig({ plan }) {
  const lease = plan?.lease;
  const { startDate, endDate, monthlyRent } = lease;
  const term = getTerm(startDate, endDate);
  const total = monthlyRent * term * LEAVE_ANYTIME_RENT_PERCENTAGE;
  const subscriptionAmount = total / term;
  const oneTimeAmount = total * (1 - LEAVE_ANYTIME_ONETIME_DISCOUNT);
  return {
    term,
    startDate,
    endDate,
    payment: {
      [planPaymentMode.ONE_TIME]: {
        amount: oneTimeAmount,
        discountPercentage: LEAVE_ANYTIME_ONETIME_DISCOUNT,
        rentPercentage: LEAVE_ANYTIME_RENT_PERCENTAGE,
        effectiveMonthlyRate: oneTimeAmount / term,
      },
      [planPaymentMode.SUBSCRIPTION]: {
        amount: subscriptionAmount,
        rentPercentage: LEAVE_ANYTIME_RENT_PERCENTAGE,
      },
    },
  };
}

function getLastStatusMessage(plan) {
  if (plan.status === planStatusEnum.REJECTED && plan.rejectedLeases) {
    let len = plan.rejectedLeases.length;
    return plan.rejectedLeases[len - 1].reason;
  } else {
    return '';
  }
}

// Uses useFormikContext to update calculated fields
const CoverageUpdater = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    const cancelAnytimeTerm = getTerm(
      values.coverage[coverageTypes.CANCEL_ANYTIME]?.startDate,
      values.coverage[coverageTypes.CANCEL_ANYTIME]?.endDate
    );
    const leaveAnytimeTerm = getTerm(
      values.coverage[coverageTypes.LEAVE_ANYTIME]?.startDate,
      values.coverage[coverageTypes.LEAVE_ANYTIME]?.endDate
    );
    const cancelAnytimeAmount = values.lease.monthlyRent * cancelAnytimeTerm * values.coverage[coverageTypes.CANCEL_ANYTIME]?.payment.rentPercentage;
    const leaveAnytimeOneTimeAmount = (values.lease.monthlyRent * leaveAnytimeTerm * values.coverage[coverageTypes.LEAVE_ANYTIME]?.payment[planPaymentMode.ONE_TIME]?.rentPercentage) * (1 - values.coverage[coverageTypes.LEAVE_ANYTIME]?.payment[planPaymentMode.ONE_TIME]?.discountPercentage);
    const leaveAnytimeSubscriptionAmount = (values.lease.monthlyRent * leaveAnytimeTerm * values.coverage[coverageTypes.LEAVE_ANYTIME]?.payment[planPaymentMode.SUBSCRIPTION]?.rentPercentage) / leaveAnytimeTerm;
    const leaveAnytimeEffectiveMonthlyAmount = leaveAnytimeOneTimeAmount / leaveAnytimeTerm;
    const buyoutAmount = values.lease.monthlyRent * values.lease.terminationPolicy.buyoutMonths;

    // Update Formik state
    setFieldValue(
      `coverage.${coverageTypes.CANCEL_ANYTIME}.term`,
      cancelAnytimeTerm
    );
    setFieldValue(
      `coverage.${coverageTypes.LEAVE_ANYTIME}.term`,
      leaveAnytimeTerm
    );
    setFieldValue(
      `coverage.${coverageTypes.CANCEL_ANYTIME}.payment.amount`,
      cancelAnytimeAmount.toFixed(2)
    );
    setFieldValue(
      `coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.ONE_TIME}.amount`,
      leaveAnytimeOneTimeAmount.toFixed(2)
    );
    setFieldValue(
      `coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.SUBSCRIPTION}.amount`,
      leaveAnytimeSubscriptionAmount.toFixed(2)
    );
    setFieldValue(
      `coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.ONE_TIME}.effectiveMonthlyAmount`,
      leaveAnytimeEffectiveMonthlyAmount.toFixed(2)
    );
    setFieldValue(
      'lease.terminationPolicy.buyout',
      buyoutAmount.toFixed(2)
    )
  }, [
    values.coverage[coverageTypes.CANCEL_ANYTIME]?.startDate,
    values.coverage[coverageTypes.CANCEL_ANYTIME]?.endDate,
    values.coverage[coverageTypes.LEAVE_ANYTIME]?.startDate,
    values.coverage[coverageTypes.LEAVE_ANYTIME]?.endDate,
    values.lease.monthlyRent,
    values.coverage[coverageTypes.CANCEL_ANYTIME]?.payment.rentPercentage,
    values.coverage[coverageTypes.LEAVE_ANYTIME]?.payment[planPaymentMode.SUBSCRIPTION]?.rentPercentage,
    values.coverage[coverageTypes.LEAVE_ANYTIME]?.payment[planPaymentMode.ONE_TIME]?.rentPercentage,
    values.coverage[coverageTypes.LEAVE_ANYTIME]?.payment[planPaymentMode.ONE_TIME]?.discountPercentage,
    values.coverage[coverageTypes.LEAVE_ANYTIME]?.payment[planPaymentMode.ONE_TIME]?.effectiveMonthlyAmount,
    values.lease.terminationPolicy.buyoutMonths,
    setFieldValue,
  ]);

  return null; // This component only performs logic, no rendering.
};

const PlanEdit = () => {
  const navigate = useNavigate();
  const { planId } = useParams();
  const isNew = !planId;

  // Get list of tenants for dropdown
  const {
    data: tenantsQuery,
    isError: isFindTenantsError,
    error: getTenantsError,
    isLoading: findTenantsLoading,
  } = useFindTenantsQuery({
    limit: 200,
    page: 1,
    sort: '-createdAt',
  });
  const { data: tenantsData, meta: tenantsMeta } = tenantsQuery || {
    data: [],
    meta: { count: 0 },
  };
  const createTenantOptionMenu = (obj) => {
    if (isEmpty(obj)) return [];

    return obj?.map(
      // eslint-disable-next-line no-unused-vars
      ({ createdAt, id, name, firstName, lastName, email, ...res }) => ({
        label: (
          <div className='w-full flex gap-2 justify-between'>
            <div className='text-md font-medium'>
              {name || `${firstName} ${lastName}` || 'Member'}
            </div>
            <div className='grid grid-cols-[1fr_100px] gap-2 text-md font-normal text-gray-500'>
              <span>{email}</span>
              <span className='text-md text-gray-500 text-right'>
                {formatDateToHumanDate(createdAt)}
              </span>
            </div>
          </div>
        ),
        value: id,
      })
    );
  };

  // Get list of properties for dropdown
  const {
    data: propertiesQuery,
    isError: isFindPropertiesError,
    error: getPropertiesError,
    isLoading: findPropertiesLoading,
  } = useFindPropertyQuery({
    limit: 200,
    page: 1,
    sort: '-createdAt',
  });
  const { data: propertiesData, meta: propertiesMeta } = propertiesQuery || {
    data: [],
    meta: { count: 0 },
  };
  const createPropertyOptionMenu = (obj) => {
    if (isEmpty(obj)) return [];
    return obj?.map(
      // eslint-disable-next-line no-unused-vars
      ({ createdAt, id, name, address, leaveAnytimeRate, cancelAnytimeRate, propertyDefaults, ...res }) => ({
        label: (
          <div className='w-full flex gap-2 justify-between'>
            <div className='text-md font-medium'>{name || 'Building'}</div>
            <div className='text-md font-normal text-gray-500'>
              {address?.streetAddress1}, {address?.city}
            </div>
          </div>
        ),
        value: id,
        leaveAnytimeRate: leaveAnytimeRate,
        cancelAnytimeRate: cancelAnytimeRate,
        address: address,
        propertyDefaults: propertyDefaults,
        owner: res?.owner,
      })
    );
  };

  // Set up endpoints for create/update plan
  const [
    updatePlan,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useUpdatePlanMutation();
  const [
    createPlan,
    { isLoading: isCreateLoading, isFetching: isCreateFetching },
  ] = useCreatePlanMutation();

  // Get userId and propertyId from query params, if available
  const queryParams = new window.URLSearchParams(window.location.search);
  const userId = queryParams.get('userId');
  const propertyId = queryParams.get('propertyId');

  // Load property if propertyId is available
  const {
    data: propertyData,
    isError: isGetPropertyError,
    error: getPropertyError,
    isLoading: isGetPropertyLoading,
  } = useGetPropertyQuery(propertyId, { skip: !propertyId });

  // Set up states for file upload
  const [uploadStatus, setUploadStatus] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });
  const [disableFileUpload, setDisableFileUpload] = useState(false);
  const [uploadRequestUrl] = useUploadRequestUrlMutation();

  // Load plan data
  const {
    data: planQuery,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
  } = useGetPlanQuery(planId, { skip: userId || !planId });
  const { data: planData } = planQuery || {
    data: {},
  };

  if (isGetError) {
    toast.error(getError?.data?.msg || 'Failed to fetch plan details');
    return (
      <DashboardWrapperLayout title={'Failed to Fetch Plan Details'}>
        <EmptyPage
          isError
          title='Failed to fetch plan details'
          subtitle={getError?.data?.msg || 'Failed to fetch plan details'}
          buttonText={'Reload Page'}
          onClick={() => window.location.reload()}
        />
      </DashboardWrapperLayout>
    );
  }

  if ((!isNew && !planData) || isGetLoading) {
    return (
      <DashboardWrapperLayout title={'Loading Plan Details'}>
        <EmptyPage
          isLoading
          title='Loading Plan Details'
          subtitle='Please wait while we load the Plan details.'
        />
      </DashboardWrapperLayout>
    );
  }

  // Data initialization for user and property
  const property = planData?.property;

  // Data initialization for street address
  const {
    streetAddress1,
    streetAddress2,
    city,
    state,
    country,
    postalCode,
    neighborhood,
  } = planData?.lease?.address ||
    property?.address || {
      streetAddress1: '',
      streetAddress2: '',
      city: '',
      neighborhood: '',
      postalCode: '',
      state: '',
      country: '',
    };

  return (
    <DashboardWrapperLayout
      title={planData?.id ? `Edit Plan Details` : `New Plan`}
      buttons={
        planData?.id ? (
          <Toggle
            selected={'edit'}
            editPath={`/dashboard/plans/${planData?.id}/edit`}
            viewPath={`/dashboard/plans/${planData?.id}`}
          />
        ) : null
      }
    >
      <Formik
        onSubmit={(value) => {
          let valueCp = JSON.parse(JSON.stringify(value));

          console.log('values: ', valueCp);

          if (!valueCp.visaExpiration) {
            delete valueCp.visaExpiration;
          }

          if (!value.property) {
            valueCp.property = null;
          }

          if (!value.user) {
            valueCp.user = null;
          }

          if (planId) {
            updatePlan({
              id: planData.id,
              ...valueCp,
            })
              .unwrap()
              .then((data) => {
                console.log(data);
                navigate(`/dashboard/plans/${data?.id}`);
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Update Failed');
              });
          } else {
            createPlan({
              user: userId,
              property: propertyId,
              ...valueCp,
            })
              .unwrap()
              .then((data) => {
                navigate(`/dashboard/plans/${data.id}`);
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Create Failed');
              });
          }
        }}
        initialValues={{
          status: planData?.status,
          movePlans: planData?.movePlans,
          jobQuitPlans: planData?.jobQuitPlans,
          owner: planData?.owner || null,
          property:
            propertyId || planData?.property?.id || planData?.property || '',
          user: userId || planData?.user?.id || planData?.user || '',
          leaseVerification: planData?.leaseVerification,
          idVerification: planData?.idVerification,
          residencyStatus: planData?.residencyStatus || 'citizenPermanent',
          visaExpiration: planData?.visaExpiration?.split('T')[0] || '',
          rentalHistory: {
            addressesLived: planData?.rentalHistory?.addressesLived,
            terminationHistory: planData?.rentalHistory?.terminationHistory,
            evictionHistory: planData?.rentalHistory?.evictionHistory,
          },
          lease: {
            address: {
              streetAddress1: planData?.lease?.streetAddress1 || propertyData?.address?.streetAddress1,
              streetAddress2: planData?.lease?.streetAddress2 || propertyData?.address?.streetAddress2,
              city: planData?.lease?.city || propertyData?.address?.city,
              postalCode: planData?.lease?.postalCode || propertyData?.address?.postalCode,
              state: planData?.lease?.state || propertyData?.address?.state,
              country: planData?.lease?.country || propertyData?.address?.country,
            },
            currency:
              planData?.lease?.currency ||
              propertyData?.paymentInformation?.currency ||
              'USD',
            landlordName:
              planData?.lease?.landlordName || propertyData?.owner?.name,
            monthlyRent: planData?.lease?.monthlyRent,
            document: planData?.lease?.document,
            signDate: toDatePickerFormat(planData?.lease?.signDate),
            startDate: toDatePickerFormat(planData?.lease?.startDate),
            endDate: toDatePickerFormat(planData?.lease?.endDate),
            furnished:
              planData?.lease?.furnished ||
              propertyData?.propertyDefaults?.furnished || false,
            concessions: {
              repayable: planData?.lease?.concessions?.repayable || false,
              discount: planData?.lease?.concessions?.discount,
              months: planData?.lease?.concessions?.months,
            },
            terminationPolicy: {
              noticePeriod:
                planData?.lease?.terminationPolicy?.noticePeriod ||
                propertyData?.propertyDefaults?.terminationPolicy?.noticePeriod,
              penalty:
                planData?.lease?.terminationPolicy?.penalty ||
                propertyData?.propertyDefaults?.terminationPolicy?.penalty,
              buyout:
                planData?.lease?.terminationPolicy?.buyout ||
                propertyData?.propertyDefaults?.terminationPolicy?.buyoutMonths *
                  planData?.lease?.monthlyRent,
              buyoutMonths:
                planData?.lease?.terminationPolicy?.buyoutMonths ||
                propertyData?.propertyDefaults?.terminationPolicy?.buyoutMonths,
            },
          },
          // NEW this is where you can get the information for coverages
          coverage: {
            [coverageTypes.CANCEL_ANYTIME]: {
              term: planData?.coverage?.[coverageTypes.CANCEL_ANYTIME]?.term,
              startDate: toDatePickerFormat(
                planData?.coverage?.[coverageTypes.CANCEL_ANYTIME]?.startDate
              ),
              endDate: toDatePickerFormat(
                planData?.coverage?.[coverageTypes.CANCEL_ANYTIME]?.endDate
              ),
              payment: {
                amount:
                  planData?.coverage?.[coverageTypes.CANCEL_ANYTIME]?.payment
                    .amount,
                rentPercentage:
                  planData?.coverage?.[coverageTypes.CANCEL_ANYTIME]?.payment
                    .rentPercentage ||
                  planData?.property?.cancelAnytimeRate ||
                  CANCEL_ANYTIME_RENT_PERCENTAGE,
                effectiveMonthlyAmount:
                  planData?.coverage?.[coverageTypes.CANCEL_ANYTIME]?.payment
                    .effectiveMonthlyAmount,
                stripe: {
                  id: planData?.coverage?.[coverageTypes.CANCEL_ANYTIME]
                    ?.payment?.stripe?.id,
                },
              },
            },
            [coverageTypes.LEAVE_ANYTIME]: {
              term: planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]?.term,
              startDate: toDatePickerFormat(
                planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]?.startDate
              ),
              endDate: toDatePickerFormat(
                planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]?.endDate
              ),
              payment: {
                [planPaymentMode.ONE_TIME]: {
                  amount:
                    planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]
                      ?.payment?.[planPaymentMode.ONE_TIME]?.amount,
                  rentPercentage:
                    planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]
                      ?.payment?.[planPaymentMode.ONE_TIME]?.rentPercentage ||
                    planData?.property?.leaveAnytimeRate ||
                    LEAVE_ANYTIME_RENT_PERCENTAGE,
                  discountPercentage:
                    planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]
                      ?.payment?.[planPaymentMode.ONE_TIME]
                      ?.discountPercentage || LEAVE_ANYTIME_ONETIME_DISCOUNT,
                  effectiveMonthlyAmount:
                    planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]
                      ?.payment?.[planPaymentMode.ONE_TIME]
                      ?.effectiveMonthlyAmount,
                  stripe: {
                    id: planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]
                      ?.payment?.[planPaymentMode.ONE_TIME]?.stripe?.id,
                  },
                },
                [planPaymentMode.SUBSCRIPTION]: {
                  amount:
                    planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]
                      ?.payment?.[planPaymentMode.SUBSCRIPTION]?.amount,
                  rentPercentage:
                    planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]
                      ?.payment?.[planPaymentMode.ONE_TIME]?.rentPercentage ||
                    planData?.property?.leaveAnytimeRate ||
                    LEAVE_ANYTIME_RENT_PERCENTAGE,
                  stripe: {
                    id: planData?.coverage?.[coverageTypes.LEAVE_ANYTIME]
                      ?.payment?.[planPaymentMode.SUBSCRIPTION]?.stripe?.id,
                  },
                },
              },
            },
          },
        }}
        validationSchema={Yup.object().shape({
          status: Yup.string().optional(),
          rejectedMessage: Yup.string().optional(),
          property: Yup.string().test(
            'is-empty-or-24-chars',
            'Must be an empty string or exactly 24 characters long',
            (value) =>
              value === '' ||
              value === null ||
              value === undefined ||
              (value && value.length === 24)
          ),
          residencyStatus: Yup.string().required(),
          visaExpiration: Yup.date(),
          user: Yup.string().test(
            'is-empty-or-24-chars',
            'Must be an empty string or exactly 24 characters long',
            (value) =>
              value === '' ||
              value === null ||
              value === undefined ||
              (value && value.length === 24)
          ),
        })}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          isValid,
          dirty: isDirty,
          values,
          setFieldValue,
        }) => {
          return (
            <Form className='flex flex-col w-full gap-8'>
              <CoverageUpdater />
              {/* Action Buttons */}
              <FloatingButtons
                button={
                  <Button
                    disabled={!isValid || !isDirty}
                    onClick={handleSubmit}
                    title='Save Changes'
                    wFull={false}
                    className='px-6'
                  />
                }
              >
                {values.owner ? (
                  <Button
                    theme='secondary'
                    type='button'
                    title='Remove Building Owner'
                    onClick={() => {
                      setFieldValue('owner', null);
                    }}
                    wFull={false}
                    className='px-6'
                  />
                ) : null}
              </FloatingButtons>

              <InfoCard title='Plan Information'>
                <InfoGrid>
                  <InfoField title='Plan Status' className='col-span-2'>
                    <SelectInput
                      options={allPlanStatusOptions}
                      placeholder='Select Status'
                      name='status'
                      value={allPlanStatusOptions.find(
                        (status) => status.value === values.status
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue('status', selectedOption?.value || '');
                      }}
                      onBlur={handleBlur}
                    />
                  </InfoField>
                  <FormError name='status' />
                </InfoGrid>
              </InfoCard>

              <InfoCard title='Property Information'>
                <InfoGrid>
                  <InfoField
                    title='Property'
                    className='col-span-2 sm:col-span-4'
                  >
                    <SelectInput
                      options={createPropertyOptionMenu(propertiesData)}
                      isDisabled={
                        isEmpty(propertiesData) || findPropertiesLoading
                      }
                      isSearchable={false}
                      placeholder={
                        findPropertiesLoading
                          ? 'Fetching...'
                          : isEmpty(propertiesData)
                          ? `No available buildings`
                          : 'Select building from the list'
                      }
                      name='property'
                      value={createPropertyOptionMenu(propertiesData).find(
                        (selectedProperty) =>
                          selectedProperty.value === values.property
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue('property', selectedOption?.value || '');
                        console.log('selectedOption: ', selectedOption);

                        // Set default rates for coverage
                        setFieldValue(`coverage.${coverageTypes.CANCEL_ANYTIME}.payment.rentPercentage`, selectedOption?.cancelAnytimeRate || CANCEL_ANYTIME_RENT_PERCENTAGE);
                        setFieldValue(`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.ONE_TIME}.rentPercentage`, selectedOption?.leaveAnytimeRate || LEAVE_ANYTIME_RENT_PERCENTAGE);

                        // Set default values for address
                        setFieldValue('lease.address.streetAddress1', selectedOption?.address?.streetAddress1 || '');
                        setFieldValue('lease.address.streetAddress2', selectedOption?.address?.streetAddress2 || '');
                        setFieldValue('lease.address.city', selectedOption?.address?.city || '');
                        setFieldValue('lease.address.state', selectedOption?.address?.state || '');
                        setFieldValue('lease.address.country', selectedOption?.address?.country || '');
                        setFieldValue('lease.address.postalCode', selectedOption?.address?.postalCode || '');

                        // Set property defaults
                        setFieldValue('lease.terminationPolicy.noticePeriod', selectedOption?.propertyDefaults?.terminationPolicy?.noticePeriod || 0);
                        setFieldValue('lease.terminationPolicy.penalty', selectedOption?.propertyDefaults?.terminationPolicy?.penalty || 0);
                        setFieldValue('lease.terminationPolicy.buyoutMonths', selectedOption?.propertyDefaults?.terminationPolicy?.buyoutMonths || 0);                        
                        setFieldValue('lease.furnished', selectedOption?.propertyDefaults?.furnished || false);

                        // Set owner name as landlordName
                        setFieldValue('lease.landlordName', selectedOption?.owner?.name || '');
                      }}
                      onBlur={handleBlur}
                    />
                    <FormError name='property' />
                  </InfoField>
                </InfoGrid>
              </InfoCard>

              <InfoCard title='Member Information'>
                <InfoGrid>
                  <InfoField
                    title='Member'
                    className='col-span-2 sm:col-span-4'
                  >
                    <SelectInput
                      options={createTenantOptionMenu(tenantsData)}
                      isDisabled={isEmpty(tenantsData) || findTenantsLoading}
                      isSearchable={false}
                      placeholder={
                        findTenantsLoading
                          ? 'Fetching...'
                          : isEmpty(tenantsData)
                          ? `No available members`
                          : 'Select member from the list'
                      }
                      name='user'
                      value={createTenantOptionMenu(tenantsData).find(
                        (selectedTenant) => selectedTenant.value === values.user
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue('user', selectedOption?.value || '');
                      }}
                      onBlur={handleBlur}
                    />
                    <FormError name='user' />
                  </InfoField>
                </InfoGrid>
                <InfoGrid>
                  <InfoField title='Job Quit Premeditated'>
                    <SelectInput
                      options={booleanOptions}
                      placeholder='Select Option'
                      name='jobQuitPlans'
                      value={booleanOptions.find(
                        (status) => status.value === values.jobQuitPlans
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue(
                          'jobQuitPlans',
                          selectedOption?.value || false
                        );
                      }}
                      onBlur={handleBlur}
                    />
                    <FormError name='jobQuitPlans' />
                  </InfoField>
                  <InfoField title='Moving Premeditated'>
                    <SelectInput
                      options={booleanOptions}
                      placeholder='Select Option'
                      name='movePlans'
                      value={booleanOptions.find(
                        (status) => status.value === values.movePlans
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue(
                          'movePlans',
                          selectedOption?.value || false
                        );
                      }}
                      onBlur={handleBlur}
                    />
                    <FormError name='movePlans' />
                  </InfoField>
                </InfoGrid>
                <InfoGrid>
                  <InfoField title='Residency Status' className='col-span-2'>
                    <SelectInput
                      options={residencyStatusOptions}
                      placeholder='Select Option'
                      name='residencyStatus'
                      value={residencyStatusOptions.find(
                        (status) => status.value === values.residencyStatus
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue(
                          'residencyStatus',
                          selectedOption?.value || ''
                        );
                      }}
                      onBlur={handleBlur}
                    />
                    <FormError name='residencyStatus' />
                  </InfoField>
                  {values.residencyStatus !== 'citizenPermanent' && (
                    <InfoField title='Visa Expiration Date'>
                      <Input
                        type='date'
                        value={values.visaExpiration}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='visaExpiration'
                      />
                      <FormError name='visaExpiration' />
                    </InfoField>
                  )}
                </InfoGrid>
              </InfoCard>

              <InfoCard title='Past 3 Years Rental History'>
                <InfoGrid>
                  <InfoField
                    title='Addressed Lived'
                    className='col-span-2 sm:col-span-1'
                  >
                    <Input
                      type='number'
                      placeholderText='Enter Number'
                      value={values.rentalHistory.addressesLived}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='rentalHistory.addressesLived'
                      suffix='addresses'
                    />
                    <FormError name='rentalHistory.addressesLived' />
                  </InfoField>
                  <InfoField
                    title='Termination History (> 2)'
                    className='col-span-2 sm:col-span-1'
                  >
                    <SelectInput
                      options={booleanOptions}
                      placeholder='Select Option'
                      name='rentalHistory.terminationHistory'
                      value={booleanOptions.find(
                        (status) =>
                          status.value ===
                          values.rentalHistory.terminationHistory
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue(
                          'rentalHistory.terminationHistory',
                          selectedOption?.value || false
                        );
                      }}
                      onBlur={handleBlur}
                    />
                    <FormError name='rentalHistory.terminationHistory' />
                  </InfoField>
                  <InfoField
                    title='Eviction History (> 1)'
                    className='col-span-2 sm:col-span-1'
                  >
                    <SelectInput
                      options={booleanOptions}
                      placeholder='Select Option'
                      name='rentalHistory.evictionHistory'
                      value={booleanOptions.find(
                        (status) =>
                          status.value === values.rentalHistory.evictionHistory
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue(
                          'rentalHistory.evictionHistory',
                          selectedOption?.value || false
                        );
                      }}
                      onBlur={handleBlur}
                    />
                    <FormError name='rentalHistory.evictionHistory' />
                  </InfoField>
                </InfoGrid>
              </InfoCard>

              <InfoCard title='Lease Information'>
                <InfoGrid>
                  <InfoField
                    title='Autofill Address'
                    className='col-span-2 sm:col-span-4'
                  >
                    <AutoCompleteAddress
                      onAddressChange={(newAddress) => {
                        setFieldValue(
                          'lease.address.streetAddress1',
                          newAddress.streetAddress1 || ''
                        );
                        setFieldValue(
                          'lease.address.streetAddress2',
                          newAddress.streetAddress2 || ''
                        );
                        setFieldValue(
                          'lease.address.city',
                          newAddress.city || ''
                        );
                        setFieldValue(
                          'lease.address.state',
                          newAddress.state || ''
                        );
                        setFieldValue(
                          'lease.address.country',
                          newAddress.country || ''
                        );
                        setFieldValue(
                          'lease.address.postalCode',
                          newAddress.zipCode || ''
                        );
                      }}
                    />
                  </InfoField>
                  <InfoField title='Street Address 1' className='col-span-2'>
                    <Input
                      placeholderText='Street Address 1'
                      value={values.lease.address.streetAddress1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='lease.address.streetAddress1'
                    />
                    <FormError name='lease.address.streetAddress1' />
                  </InfoField>
                  <InfoField title='Street Address 2' className='col-span-2'>
                    <Input
                      placeholderText='Street Address 2'
                      value={values.lease.address.streetAddress2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='lease.address.streetAddress2'
                    />
                    <FormError name='lease.address.streetAddress2' />
                  </InfoField>
                  <InfoField title='City'>
                    <Input
                      placeholderText='City'
                      value={values.lease.address.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='lease.address.city'
                    />
                    <FormError name='lease.address.city' />
                  </InfoField>
                  <InfoField title='State'>
                    <Input
                      placeholderText='State'
                      value={values.lease.address.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='lease.address.state'
                    />
                    <FormError name='lease.address.state' />
                  </InfoField>
                  <InfoField title='Postal Code'>
                    <Input
                      placeholderText='Postal Code'
                      value={values.lease.address.postalCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='lease.address.postalCode'
                    />
                    <FormError name='lease.address.postalCode' />
                  </InfoField>
                  <InfoField title='Country'>
                    <Input
                      placeholderText='Country'
                      value={values.lease.address.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='lease.address.country'
                    />
                    <FormError name='lease.address.country' />
                  </InfoField>
                </InfoGrid>
                <InfoGrid>
                  <InfoField title='Signing Date'>
                    <Input
                      type='date'
                      value={values.lease.signDate}
                      onChange={(event) => {
                        handleChange(event);
                        setFieldValue(
                          `coverage.${coverageTypes.CANCEL_ANYTIME}.startDate`,
                          event.target.value
                        );
                      }}
                      onBlur={handleBlur}
                      name='lease.signDate'
                    />
                    <FormError name='lease.signDate' />
                  </InfoField>
                  <InfoField title='Start Date'>
                    <Input
                      type='date'
                      value={values.lease.startDate}
                      onChange={(event) => {
                        handleChange(event);
                        setFieldValue(
                          `coverage.${coverageTypes.CANCEL_ANYTIME}.endDate`,
                          event.target.value
                        );
                        setFieldValue(
                          `coverage.${coverageTypes.LEAVE_ANYTIME}.startDate`,
                          event.target.value
                        );
                      }}
                      onBlur={handleBlur}
                      name='lease.startDate'
                    />
                    <FormError name='lease.startDate' />
                  </InfoField>
                  <InfoField title='End Date'>
                    <Input
                      type='date'
                      value={values.lease.endDate}
                      onChange={(event) => {
                        handleChange(event);
                        setFieldValue(
                          `coverage.${coverageTypes.LEAVE_ANYTIME}.endDate`,
                          event.target.value
                        );
                      }}
                      onBlur={handleBlur}
                      name='lease.endDate'
                    />
                    <FormError name='lease.endDate' />
                  </InfoField>
                </InfoGrid>
                <InfoGrid>
                  <InfoField title='Landlord Name'>
                    <Input
                      placeholderText='Name'
                      value={values.lease.landlordName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='lease.landlordName'
                    />
                    <FormError name='lease.landlordName' />
                  </InfoField>
                  <InfoField title='Monthly Rent'>
                    <Input
                      type='number'
                      placeholderText='Enter Number'
                      value={values.lease.monthlyRent}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='lease.monthlyRent'
                      prefix='$'
                      suffix={values.lease.currency}
                    />
                    <FormError name='lease.monthlyRent' />
                  </InfoField>
                  <InfoField title='Currency'>
                    <SelectInput
                      options={planCurrencyOptions}
                      placeholder='Select Currency'
                      name='lease.currency'
                      value={planCurrencyOptions.find(
                        (status) => status.value === values.lease.currency
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue(
                          'lease.currency',
                          selectedOption?.value || ''
                        );
                      }}
                      onBlur={handleBlur}
                    />
                  </InfoField>
                  <InfoField title='Furnished?'>
                    <SelectInput
                      options={booleanOptions}
                      placeholder='Select Option'
                      name='lease.furnished'
                      value={booleanOptions.find(
                        (status) => status.value === values.lease.furnished
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue(
                          'lease.furnished',
                          selectedOption?.value || false
                        );
                      }}
                      onBlur={handleBlur}
                    />
                    <FormError name='lease.furnished' />
                  </InfoField>
                </InfoGrid>
                <InfoGrid>                  
                  <InfoField title='Repayable Concessions'>
                    <SelectInput
                      options={booleanOptions}
                      placeholder='Select Option'
                      name='lease.concessions.repayable'
                      value={booleanOptions.find(
                        (status) =>
                          status.value === values.lease.concessions.repayable
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue(
                          'lease.concessions.repayable',
                          selectedOption?.value || false
                        );
                      }}
                      onBlur={handleBlur}
                    />
                    <FormError name='lease.concessions.repayable' />
                  </InfoField>
                  {values.lease.concessions.repayable ? (
                    <>
                      <InfoField title='Concession Discount'>
                    <Input
                      type='number'
                      placeholderText='Enter Decimal'
                      value={values.lease.concessions.discount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='lease.concessions.discount'
                    />
                    <FormError name='lease.concessions.discount' />
                  </InfoField>
                  <InfoField title='Concession Months'>
                    <Input
                      type='number'
                      placeholderText='Enter Number'
                      value={values.lease.concessions.months}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='lease.concessions.months'
                      suffix='Months'
                    />
                    <FormError name='lease.concessions.months' />
                  </InfoField>
                    </>
                  ) : null}
                </InfoGrid>
              </InfoCard>

              {/* Uploaded Lease and Termination Policy */}
              <InfoCard title='Lease Agreement'>
                <InfoGrid>
                  <InfoField
                    title='Lease Verification'
                    className='col-span-2 sm:col-span-1'
                  >
                    <SelectInput
                      options={documentStatusOptions}
                      placeholder='Select Status'
                      name='leaseVerification'
                      value={documentStatusOptions.find(
                        (status) => status.value === values.leaseVerification
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue(
                          'leaseVerification',
                          selectedOption?.value || ''
                        );
                      }}
                      onBlur={handleBlur}
                    />
                  </InfoField>
                  <InfoField title='Upload New Lease' className='col-span-2'>
                    <FileUploadInput
                      disableUpload={disableFileUpload}
                      isUploadError={uploadStatus.isError}
                      isUploadLoading={uploadStatus.isLoading}
                      isUploadSuccess={
                        uploadStatus.isSuccess ||
                        !isEmpty(planData?.lease?.document)
                      }
                      handlePDFClear={() => {
                        setFieldValue('lease.document', '');
                      }}
                      handlePDFUpload={async (file) => {
                        let uploadUrlReq;
                        setUploadStatus({
                          isSuccess: false,
                          isLoading: true,
                          isError: false,
                        });

                        // Get the signing url for uploading to s3
                        try {
                          uploadUrlReq = await uploadRequestUrl({
                            fileName: file.name,
                            contentType: file.type,
                          }).unwrap();
                        } catch (e) {
                          setUploadStatus({
                            isSuccess: false,
                            isError: true,
                            isLoading: false,
                          });
                          toast.error(
                            e?.msg || e?.message || 'failed to upload document'
                          );
                          return;
                        }

                        if (
                          !uploadUrlReq?.data?.uploadUrl ||
                          !uploadUrlReq?.data?.viewUrl
                        ) {
                          setUploadStatus({
                            isSuccess: false,
                            isError: true,
                            isLoading: false,
                          });
                          toast.error('failed to upload document');
                        }

                        // Upload the file to s3

                        try {
                          await uploadFileToS3({
                            s3Url: uploadUrlReq?.data?.uploadUrl,
                            file,
                          });

                          // Have to set the uploaded file url on the lease document
                          setUploadStatus({
                            isSuccess: true,
                            isError: false,
                            isLoading: false,
                          });
                          setDisableFileUpload(true);
                          setFieldValue(
                            'lease.document',
                            uploadUrlReq?.data?.viewUrl
                          );
                          toast.info(
                            uploadUrlReq?.status ||
                              'file has been uploaded successfully'
                          );
                        } catch (e) {
                          setUploadStatus({
                            isSuccess: false,
                            isError: true,
                            isLoading: false,
                          });
                          toast.error(
                            e?.msg || e?.message || 'failed to upload document'
                          );
                          return;
                        }
                      }}
                    />
                    <FormError name='lease.document' />
                  </InfoField>
                </InfoGrid>
                <InfoGrid>
                  <InfoField title='Termination Notice Period'>
                    <Input
                      type='number'
                      placeholderText='Enter Number'
                      value={values.lease.terminationPolicy.noticePeriod}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='lease.terminationPolicy.noticePeriod'
                      suffix='Days'
                    />
                    <FormError name='lease.terminationPolicy.noticePeriod' />
                  </InfoField>
                  <InfoField title='Termination Penalty'>
                    <Input
                      type='number'
                      placeholderText='Enter Number'
                      value={values.lease.terminationPolicy.penalty}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='lease.terminationPolicy.penalty'
                      prefix='$'
                      suffix={planData?.lease?.currency}
                    />
                    <FormError name='lease.terminationPolicy.penalty' />
                  </InfoField>
                  <InfoField title='Termination Buyout Months'>
                    <Input
                      type='number'
                      placeholderText='Enter Number'
                      value={values.lease.terminationPolicy.buyoutMonths}
                      onChange={(event) => {
                        const { value } = event.target;
                        // Call handleChange to update the first field
                        handleChange(event);

                        // Update the second field
                        setFieldValue(
                          'lease.terminationPolicy.buyout',
                          value * values.lease.monthlyRent
                        );
                      }}
                      onBlur={handleBlur}
                      name='lease.terminationPolicy.buyoutMonths'
                      suffix='months'
                    />
                    <FormError name='lease.terminationPolicy.buyoutMonths' />
                  </InfoField>
                  <InfoField title='Termination Buyout Amount'>
                    <Input
                      type='number'
                      placeholderText='Enter Number'
                      value={values.lease.terminationPolicy.buyout}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='lease.terminationPolicy.buyout'
                      prefix='$'
                      suffix={planData?.lease?.currency}
                      disabled
                    />
                    <FormError name='lease.terminationPolicy.buyout' />
                  </InfoField>
                </InfoGrid>
              </InfoCard>

              {/* Coverage Information */}
              <InfoCard 
                title={
                  <div className='flex gap-3 items-center'>
                    Cancel Anytime Coverage {values.coverage[coverageTypes.CANCEL_ANYTIME].payment.stripe.id ? '' : (
                      <Tag colorScheme='red' size='sm'>Not Attached</Tag>
                    )}
                  </div>
                }
              >
                <InfoGrid>
                  <InfoField title='Term'>
                    <Input
                      type='number'
                      placeholderText='Enter Number'
                      value={getTerm(
                        values.coverage[coverageTypes.CANCEL_ANYTIME].startDate,
                        values.coverage[coverageTypes.CANCEL_ANYTIME].endDate
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.CANCEL_ANYTIME}.term`}
                      suffix='Months'
                      disabled
                    />
                    <FormError
                      name={`coverage.${coverageTypes.CANCEL_ANYTIME}.term`}
                    />
                  </InfoField>
                  <InfoField title='Start Date'>
                    <Input
                      type='date'
                      value={
                        values.coverage[coverageTypes.CANCEL_ANYTIME].startDate
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.CANCEL_ANYTIME}.startDate`}
                    />
                    <FormError
                      name={`coverage.${coverageTypes.CANCEL_ANYTIME}.startDate`}
                    />
                  </InfoField>
                  <InfoField title='End Date'>
                    <Input
                      type='date'
                      value={
                        values.coverage[coverageTypes.CANCEL_ANYTIME].endDate
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.CANCEL_ANYTIME}.endDate`}
                    />
                    <FormError
                      name={`coverage.${coverageTypes.CANCEL_ANYTIME}.endDate`}
                    />
                  </InfoField>
                </InfoGrid>
                <InfoGrid>
                  <InfoField title='Payment Amount'>
                    <Input
                      type='number'
                      placeholderText='Enter Number'
                      value={
                        values.coverage[coverageTypes.CANCEL_ANYTIME].payment
                          .amount
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.CANCEL_ANYTIME}.payment.amount`}
                      prefix='$'
                      suffix={values.lease.currency}
                      disabled
                    />
                    <FormError
                      name={`coverage.${coverageTypes.CANCEL_ANYTIME}.payment.amount`}
                    />
                  </InfoField>
                  <InfoField title='Rate'>
                    <Input
                      type='number'
                      placeholderText='Enter Decimal'
                      value={
                        values.coverage[coverageTypes.CANCEL_ANYTIME].payment
                          .rentPercentage
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.CANCEL_ANYTIME}.payment.rentPercentage`}
                    />
                    <FormError
                      name={`coverage.${coverageTypes.CANCEL_ANYTIME}.payment.rentPercentage`}
                    />
                  </InfoField>
                  <InfoField title='Stripe Payment ID'>
                    <Input
                      placeholderText='Stripe Payment ID'
                      value={
                        values.coverage[coverageTypes.CANCEL_ANYTIME].payment
                          .stripe.id
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.CANCEL_ANYTIME}.payment.stripe.id`}
                    />
                    <FormError
                      name={`coverage.${coverageTypes.CANCEL_ANYTIME}.payment.stripe.id`}
                    />
                  </InfoField>
                </InfoGrid>
              </InfoCard>

              <InfoCard 
                title={
                  <div className='flex gap-3 items-center'>
                    Leave Anytime Coverage {values.coverage[coverageTypes.LEAVE_ANYTIME].payment[planPaymentMode.ONE_TIME].stripe.id || values.coverage[coverageTypes.LEAVE_ANYTIME].payment[planPaymentMode.SUBSCRIPTION].stripe.id ? '' : (
                      <Tag colorScheme='red' size='sm'>Not Attached</Tag>
                    )}
                  </div>
                }
              >
                <InfoGrid>
                  <InfoField title='Term'>
                    <Input
                      type='number'
                      placeholderText='Enter Number'
                      value={values.coverage[coverageTypes.LEAVE_ANYTIME].term}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.term`}
                      suffix='Months'
                      disabled
                    />
                    <FormError
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.term`}
                    />
                  </InfoField>
                  <InfoField title='Start Date'>
                    <Input
                      type='date'
                      value={
                        values.coverage[coverageTypes.LEAVE_ANYTIME].startDate
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.startDate`}
                    />
                    <FormError
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.startDate`}
                    />
                  </InfoField>
                  <InfoField title='End Date'>
                    <Input
                      type='date'
                      value={
                        values.coverage[coverageTypes.LEAVE_ANYTIME].endDate
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.endDate`}
                    />
                    <FormError
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.endDate`}
                    />
                  </InfoField>
                </InfoGrid>
                <InfoGrid>
                  <InfoField title='One Time Payment Amount'>
                    <Input
                      type='number'
                      placeholderText='Enter Number'
                      value={
                        values.coverage[coverageTypes.LEAVE_ANYTIME].payment[
                          planPaymentMode.ONE_TIME
                        ].amount
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.ONE_TIME}.amount`}
                      prefix='$'
                      suffix={values.lease.currency}
                      disabled
                    />
                    <FormError
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.ONE_TIME}.amount`}
                    />
                  </InfoField>
                  <InfoField title='Effective Monthly Amount'>
                    <Input
                      type='number'
                      placeholderText='Enter Number'
                      value={
                        values.coverage[coverageTypes.LEAVE_ANYTIME].payment[
                          planPaymentMode.ONE_TIME
                        ].effectiveMonthlyAmount
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.ONE_TIME}.effectiveMonthlyAmount`}
                      prefix='$'
                      suffix={values.lease.currency}
                      disabled
                    />
                    <FormError
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.ONE_TIME}.amount`}
                    />
                  </InfoField>
                  <InfoField title='Rate'>
                    <Input
                      type='number'
                      placeholderText='Enter Decimal'
                      value={
                        values.coverage[coverageTypes.LEAVE_ANYTIME].payment[
                          planPaymentMode.ONE_TIME
                        ].rentPercentage
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.ONE_TIME}.rentPercentage`}
                    />
                    <FormError
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.ONE_TIME}.rentPercentage`}
                    />
                  </InfoField>
                  <InfoField title='Discount Rate'>
                    <Input
                      type='number'
                      placeholderText='Enter Decimal'
                      value={
                        values.coverage[coverageTypes.LEAVE_ANYTIME].payment[
                          planPaymentMode.ONE_TIME
                        ].discountPercentage
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.ONE_TIME}.discountPercentage`}
                    />
                    <FormError
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.ONE_TIME}.discountPercentage`}
                    />
                  </InfoField>
                  <InfoField title='Stripe Payment ID'>
                    <Input
                      placeholderText='Stripe Payment ID'
                      value={
                        values.coverage[coverageTypes.LEAVE_ANYTIME].payment[
                          planPaymentMode.ONE_TIME
                        ].stripe.id
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.ONE_TIME}.stripe.id`}
                    />
                    <FormError
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.ONE_TIME}.stripe.id`}
                    />
                  </InfoField>
                </InfoGrid>
                <InfoGrid>
                  <InfoField title='Subscription Monthly Payment Amount'>
                    <Input
                      type='number'
                      placeholderText='Enter Number'
                      value={
                        values.coverage[coverageTypes.LEAVE_ANYTIME].payment[
                          planPaymentMode.SUBSCRIPTION
                        ].amount
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.SUBSCRIPTION}.amount`}
                      prefix='$'
                      suffix={values.lease.currency}
                      disabled
                    />
                    <FormError
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.SUBSCRIPTION}.amount`}
                    />
                  </InfoField>
                  <InfoField title='Rate'>
                    <Input
                      type='number'
                      placeholderText='Enter Decimal'
                      value={
                        values.coverage[coverageTypes.LEAVE_ANYTIME].payment[
                          planPaymentMode.SUBSCRIPTION
                        ].rentPercentage
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.SUBSCRIPTION}.rentPercentage`}
                    />
                    <FormError
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.SUBSCRIPTION}.rentPercentage`}
                    />
                  </InfoField>
                  <InfoField title='Stripe Subscription ID'>
                    <Input
                      placeholderText='Stripe Subscription ID'
                      value={
                        values.coverage[coverageTypes.LEAVE_ANYTIME].payment[
                          planPaymentMode.SUBSCRIPTION
                        ].stripe.id
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.SUBSCRIPTION}.stripe.id`}
                    />
                    <FormError
                      name={`coverage.${coverageTypes.LEAVE_ANYTIME}.payment.${planPaymentMode.SUBSCRIPTION}.stripe.id`}
                    />
                  </InfoField>
                </InfoGrid>
              </InfoCard>
            </Form>
          );
        }}
      </Formik>
    </DashboardWrapperLayout>
  );
};

export default PlanEdit;
