import React from "react";

const CheckBox = ({
  checked,
  value,
  onBlur,
  onFocus,
  name,
  onChange,
  disabled,
  required,
  className,
  innerClassName,
  children,
}) => {
  return (
    <label className={`flex flex-row gap-2 items-center capitalize text-md ${className}`}>
      <input
        disabled={disabled}
        type="checkbox"
        name={name}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        checked={checked}
        onChange={onChange}
        required={required}
        className={`h-3 w-3 border border-gray-200 rounded-md ${innerClassName}`}
      />
      {children}
    </label>
  );
};

export default CheckBox;
