import { useEffect, useState } from 'react';
import HamburgerMenu from 'common/components/dashboard/hamburger-menu';
import LogoImage from 'common/components/logo-image';
import ProfileLink from 'common/components/dashboard/profile-link';

const NavBar = ({ links, user, onLogoutClick }) => {
  // STATES
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [show, setShow] = useState(false);

  // HANDLERS
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY < 40) {
        setShow(false);
      } else if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  // SIDE EFFECTS
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, [isMenuOpen]);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <header
      className={`w-full hidden mobile:flex items-center justify-center bg-transparent bg border-none mt-0 h-fit flex-col sticky top-0 z-40 bg-white [transition:transform_.4s_ease-in-out]'
      }`}
    >
      <nav className='bg-white flex justify-between gap-3 items-center w-full px-4 sm:px-6 lg:px-8 my-auto h-12 border-b border-gray-200 z-40'>
        {/* hamburger menu */}
        <div className='flex gap-3'>
          <HamburgerMenu isMenuOpen={isMenuOpen} onClick={toggleMenu} />        
          <LogoImage size='xs' /> 
        </div>   
        {/* profile details and logout */}
        <ProfileLink
          user={user}
          onClick={onLogoutClick}
        />    
      </nav>

      {/* mobile menu */}
      <ul
        className={`text-gray-500 flex flex-col [transition:transform_.4s_ease-in-out] w-full fixed h-full overflow-auto left-0 top-12 bottom-12 z-40 ${
          isMenuOpen ? 'bg-black/25' : 'hidden'
        }`}
      >
        <div
          className={`w-full h-full bg-white px-3 sm:px-5 lg:px-7 py-6 text-center gap-2 text-sm font-normal flex flex-col max-w-[240px] border-r border-gray-200 [transition:transform.4s_ease-in-out] ${
            isMenuOpen ? 'translate-x-0' : 'translate-x-[-100dvw]'
          }`}
        >
          {/* link buttons */}
          <div className='flex flex-col gap-4'>
            {links}
          </div>          
        </div>
      </ul>
    </header>
  );
};

export default NavBar;
