import { useState } from 'react';
import { toast } from 'react-toastify';
import LoadingSpinner from 'components/shared/loading-spinner';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useDeleteOwnerMutation } from 'redux/api/ciosAdminApi/ciosAdminApi';
import StatusLabel from 'components/owners/status-label';
import { BodyRow, HeaderCell, HeaderRow, Table, TableBody, TableCell } from 'common/components/data-table';
import SortButton from 'common/components/sort-button';
import { formatPhoneNumber } from 'react-phone-number-input';
import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { AddIcon, HamburgerIcon, ViewIcon } from '@chakra-ui/icons';
import ConfirmActionModal from 'common/components/confirm-action-modal';
import FormatAsCurrency from 'common/utils/formatAsCurrency';

const OwnersTable = ({ owners, sortVal, setSort }) => {
  // DATA INITIALIZATION
  const navigate = useNavigate();
  const [
    deleteOwner,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useDeleteOwnerMutation();
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });

  const gridLayout = 'grid-cols-4 mobile:grid-cols-2'

  return (
    <Table>
      {deleteModal.open && (
        <ConfirmActionModal
          action={`delete this company`}          
          onConfirmClick={() => {
            deleteOwner({ id: deleteModal.id })
              .unwrap()
              .then(() => {
                setDeleteModal({ open: false, id: null });
                navigate('/dashboard/owners');
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Delete Failed');
              });
          }}
          onCancel={() => {
            setDeleteModal({ open: false, id: null });
          }}
        />
      )}

      {/* head */}
      <HeaderRow isRounded={true} gridLayout={gridLayout}>
        <HeaderCell sortColumn>
          <SortButton setSort={setSort} sortVal={sortVal} field='name' label='Company Name'/>
        </HeaderCell>
        <HeaderCell className='mobile:hidden' >
          Contact Info
        </HeaderCell>
        <HeaderCell className='mobile:hidden' >
          Commission
        </HeaderCell>
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='status'
            label='Status'
          />
        </HeaderCell>
      </HeaderRow>

      {/* body */}
      <TableBody>
        {owners?.map((owner, index) => (
          <BodyRow
            gridLayout={gridLayout}
            key={index}
            onClick={() => {
              navigate(`/dashboard/owners/${owner.id}`);
            }}
          >
            {/* Name + ID */}
            <TableCell
              top={owner?.name}
              bottom={owner?.id}
            />

            {/* Email + Phone */}
            <TableCell
              top={owner?.email || '-'}
              topLink={`mailto:${owner?.email}`}
              bottom={formatPhoneNumber(owner?.phone) || '-'}
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
              className='mobile:hidden'
            />
            
            {/* Commission Structure */}
            <TableCell 
              top={`${owner?.revShareRate ? ((owner?.revShareRate*100).toFixed(2)) : ('0')}% Rev Share`}
              bottom={`${FormatAsCurrency(owner?.flatCommission || 0, 'USD')} Flat Commission`}
              className='mobile:hidden'
            />

            {/* Status */}
            <TableCell
              className='justify-between'
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            >
              <StatusLabel status={owner.status} size='sm' />
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<HamburgerIcon />}
                  variant='ghost'
                />
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/owners/${owner?.id}`);
                    }}
                    icon={<ViewIcon />}
                  >
                    View Owner Details
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/owners/${owner?.id}/edit`);
                    }}
                    icon={<BiEdit />}
                  >
                    Edit Owner Details
                  </MenuItem>
                  <MenuItem 
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/dashboard/properties/new?ownerId=${owner?.id}`);
                      }}
                      icon={<AddIcon />}
                    >
                      Add Property to Owner
                    </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteModal({ open: true, id: owner?.id });
                    }}
                    icon={<AiOutlineDelete />}
                  >
                    Delete Owner
                  </MenuItem>
                </MenuList>
              </Menu>
            </TableCell>
          </BodyRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default OwnersTable;
