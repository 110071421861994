import { useState } from 'react';
import MembersTable from 'components/members/members-table';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import TablePaginateWrapper from 'common/layouts/table-paginate-wrapper';
import { isEmpty } from 'utils/isEmpty';
import { useFindTenantsQuery } from 'redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import SearchBar from 'common/components/search-bar';
import EmptyPage from 'common/components/empty-page';

const Tenants = ({ statuses }) => {
  const navigate = useNavigate();
  // DATA INITIALIZATION
  const pageSize = 15;

  // STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [sortVal, setSort] = useState('-createdAt');
  const [textFilter, setTextFilter] = useState('');

  const {
    data: tenantsQuery,
    isError: isGetError,
    error: getError,
    isLoading: findTenantsLoading,
  } = useFindTenantsQuery({
    limit: pageSize,
    page: currentPage,
    sort: sortVal,
    textFilter,
    status: statuses,
  });

  const { data: tenantsData, meta: tenantsMeta } = tenantsQuery || {
    data: [],
    meta: { count: 0 },
  };

  // HOOKS

  return (
    <DashboardWrapperLayout 
      title='Members'
      buttons={
        !isGetError ? (
        <SearchBar
          placeholderText='Search by ID'
          value={textFilter}
          onSearch={(e) => setTextFilter(e.target.value)}
          buttonText={'Add Member'}
          onClick={() => navigate(`/dashboard/members/new`)}
        />
        ) : null
      }
    >
      {isGetError ? (
        <EmptyPage
          isError={isGetError}
          title='Error Loading Members'
          subtitle={getError?.data?.msg}
          buttonText='Reload Page'
          onClick={() => window.location.reload()}
        />
      ) : findTenantsLoading ? (
        <EmptyPage
          title='Loading Members'
          subtitle={'Please wait while we load your members'}
          isLoading={findTenantsLoading}
        />
      ) : isEmpty(tenantsData) ? (
        <EmptyPage
          title='No Members Found'
          subtitle={'There are no members available'}
          buttonText={'Add Member'}
          onClick={() => navigate(`/dashboard/members/new`)}
        />
      ) : (
        <TablePaginateWrapper
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          currentPage={currentPage}
          totalCount={tenantsMeta.count}
        >
          <MembersTable
            sortVal={sortVal}
            setSort={setSort}
            members={tenantsData}
          />
        </TablePaginateWrapper>
      )}
    </DashboardWrapperLayout>
  );
};

export default Tenants;
