import { useLocation } from "react-router-dom";
import { NavLink } from "common/utils/user-tracker/react-router-dom";

const ActiveLink = ({
  to,
  Icon,
  linkId,
  text = "Insert Link",
  className = "",
}) => {
  // DATA INITIALIZATION
  const location = useLocation();
  const isActive = location.pathname.includes(to);
  return (
    <NavLink
      end
      linkId={linkId}
      to={to}
      className={`flex py-2 px-4 gap-4 transition-all rounded-md hover:no-underline items-center ${className}
        ${isActive ? "hover:opacity-70 bg-black" : "hover:bg-gray-50"}
      `}
    >
      {/* icon */}
      {isActive ? (
        <Icon className="text-white text-xl self-center" />
      ) : (
        <Icon className="text-gray-500 text-xl self-center" />
      )}

      {/* text */}
      {isActive ? (
        <span className="font-medium text-base text-white">{text}</span>
      ) : (
        <span className="font-medium text-base text-gray-500">{text}</span>
      )}
    </NavLink>
  );
};

export default ActiveLink;
