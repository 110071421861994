import React from 'react';
import { ownerStatusEnum } from 'common/utils/enums';
import StatusLabelView from 'common/components/status-label';

const StatusLabel = ({ status = 'In Review' }) => {
  // DATA INITIALIZATION
  let colorScheme = '';

  switch (status) {
    case ownerStatusEnum.ACTIVE:
      colorScheme = 'green';
      break;
    case ownerStatusEnum.DELETED:
      colorScheme = 'red';
      break;
    default:
      colorScheme = 'gray';
  }

  return <StatusLabelView colorScheme={colorScheme} status={status} />;
};

export default StatusLabel;
